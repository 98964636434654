import { Component, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import * as _ from 'lodash';
import {
  Router,
  NavigationStart,
  Event,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import { AppService } from "./app.service";
import { AuthService } from "./core/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { StoresDialogComponent } from "./stores-dialog/stores-dialog.component";
import { Lang } from "./core/model";
import { StoresComponent } from './stores/stores.component';
import { Store } from 'src/app/core/model';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ListBottomsheetComponent } from './stores/list-bottomsheet/list-bottomsheet.component';



export interface Language {
  value: string;
  name: string;
}



@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild(StoresComponent) storeComponent: StoresComponent;

  title = "차별없는가게";

  // focus 지정 https://stackoverflow.com/questions/54474637/how-to-set-matbutton-active-focused-angular
  // child Component focus
  href: string;
  route: string;
  langs: Array<Language> = [
    { value: "ko", name: "한국어" },
    { value: "en", name: "English" },
  ];

  selectedLang = this.langs[0];

  skipLinkPath: string;

  stores: Array<Store>;
  isViewList = false;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private location: Location,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    public matDialog: MatDialog
  ) {
    // this.selectedLang = '한국어'
    this.setLanguageBtnByUrlParam();
  }

  ngOnInit() {
    this.getStores();
    // conso
    
  }

  onViewStoreList() {
    console.log('onViewStoreList!');
    this.isViewList = !this.isViewList;

    if (this.isViewList) {
      const bottomSheetRef = this.bottomSheet.open(
        ListBottomsheetComponent, { data: this.stores });
      

      // listBottomSheet 닫힐 때
      bottomSheetRef
        .afterDismissed()
        .subscribe(val => this.isViewList = false);
    }
  }


  getStores() {
    // console.log('getStores() in AppComponent');

    this.appService.getStores()
      .subscribe(stores =>  {
        // console.log('stores', stores);
        this.stores = stores;
      });

  }


  

  onSelectionChange(lang: Language) {
    this.selectedLang = lang;
    this.appService.updateCurrenLang$(lang);
  }


  

  locationBack() {
    this.location.back();
  }

  signOut() {
    this.authService.signOut();
  }

  

  // About page의 언어 선택 버튼을 표시한다 (with URL Param)
  setLanguageBtnByUrlParam() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // console.log('navigationend', this.router.url);
        this.route = this.router.url;
        // this.skipLinkPath = `${this.router.url}#btn-view_list`;
        // console.log("skipLinkPath", this.skipLinkPath);
        // this.selectedLang.value = this.route === "/about-en" ? "en" : "ko";
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
  }
}
