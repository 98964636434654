import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { IconTagComponent } from './icon-tag/icon-tag.component';
import { IconSnsComponent } from './icon-sns/icon-sns.component';
import { IconSvgComponent } from './icon-svg/icon-svg.component';
import { HttpClientModule } from '@angular/common/http';

import { AngularSvgIconModule } from 'angular-svg-icon';


export const UI_COMPONENTS = [
  IconTagComponent,
  IconSnsComponent,
  IconSvgComponent,
];
export const UI_MODULES = [
  MatMenuModule,
  MatButtonModule,
  MatListModule,
  MatIconModule
];

@NgModule({
  declarations: [
    UI_COMPONENTS
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    AngularSvgIconModule,
    UI_MODULES
  ],
  exports: [
    UI_COMPONENTS
  ]
})
export class UiModule { }
