export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyAx4uxiZ-sgDb0thGrBEHX7KmitpC_Uj1Q',
    authDomain: 'inclusive-store-kr.firebaseapp.com',
    databaseURL: 'https://inclusive-store-kr.firebaseio.com',
    projectId: 'inclusive-store-kr',
    storageBucket: 'inclusive-store-kr.appspot.com',
    messagingSenderId: '529163861851',
    appId: '1:529163861851:web:4e4ce395a233e8db84e47e',
    measurementId: 'G-5XGCN273D5'
  }
};
