import { Router } from '@angular/router';
import { AuthService } from './../../core/auth.service';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user;
  email;
  password;
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService
      .user.subscribe(user => this.user = user);
  }

  login() {
    this.authService.emailLogin(this.email, this.password);
  }

  gotoPage(page: string) {
    this.router.navigate([page]);
  }
}