import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { User } from './model';


@Injectable({ providedIn: 'root' })
export class AuthService {
  authState: any = null;
  user: Observable<User>;
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    public snackBar: MatSnackBar
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          // console.log('auth service user', user);
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }






  //// Check Role ////
  canRead(user: User): boolean {
    const allowed = ['admin', 'visitor'];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: User): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: User): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }


  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) {
      return false;
    }
    for (const role of allowedRoles) {
      if ( user.roles[role] ) {
        return true;
      }
    }
    return false;
  }


  //// 이메일 로그인 ///
  emailLogin(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then(user => {
        // console.log('email login success', user);
        this.updateUserData(user); // at once;
        this.afterSignIn();
      })
      .catch(error => {
        console.log(error);
        this.openSnackBar('정보가 맞지 않습니다. :(');
      });
  }



  updateUser(user: User, data: any) {
    return this.afs.doc(`user/${user.uid}`).update(data);
  }


  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data = { lastLogin: new Date() };
    return userRef.set(data, { merge: true });
  }




  signOut() {
    this.afAuth.auth.signOut().then(() => {
        this.router.navigate(['/']);
    });
  }

  private afterSignIn(): void {
    this.openSnackBar('반가워요 :)');
    this.router.navigate(['/admin']);
  }

  private handleError(error) {
    console.error(error);
    // this.notify.update(error.message, 'error');
  }

  openSnackBar(msg: string) {
    this.snackBar.open(msg, '', {
      duration: 2000
    });
  }

}