<section class="store-detail-container">
	<div class="store-detail">
		<h3 class="is-visually-hidden">기본 정보</h3>
		<div class="store-info">
			<ul class="store-info-list"
				[class.is-view_list]="layoutType === 'list'">
				<!-- 주소 -->
				<li class="store-info-item">
					<figure class="icon-container" 
						aria-hidden="true">
						<img class="icon" src="/assets/images/ui/icons/addr@2x.png" alt="" />
					</figure>
					<span class="is-visually-hidden">주소</span>

					<div class="value-container">
						<span class="value">{{ store.addr }}</span>
						<!-- <div class="tips">
							<span class="tip">길찾기 도움말</span>
						</div> -->
					</div>
					
				</li>
				<!-- 운영시간 -->
				<li class="store-info-item"
					 *ngIf="store.openingInfos?.length">
					<figure class="icon-container" aria-hidden="true">
						<img class="icon" src="/assets/images/ui/icons/openingInfos@2x.png" alt="" />
					</figure>
					<span class="is-visually-hidden">운영시간</span>
					<ul class="openingInfo-list">
						<li class="openningInfo-item" *ngFor="let info of store.openingInfos">
							<span class="value">{{ info.day }}: {{ info.open }} - {{ info.close }}</span>
						</li>
					</ul>
				</li>
				
				<!-- 전화번호 -->
				<li class="store-info-item" *ngIf="store.phone">
					<figure class="icon-container" aria-hidden="true">
						<img class="icon" src="/assets/images/ui/icons/phone@2x.png" alt="">
					</figure>
					<span class="is-visually-hidden">전화번호</span>
					<span class="value">{{store.phone}}</span>
				</li>


				<!-- 온라인채널 optional -->
				<li class="store-info-item" *ngIf="store.onlineChannels.length">
					<figure class="icon-container" aria-hidden="true">
						<img class="icon" src="/assets/images/ui/icons/onlineChannels@2x.png" alt="">
					</figure>
					<span class="is-visually-hidden">온라인 채널</span>
					<!-- 온라인 채널 목록 -->
					<ul class="inner-list is-singleline">
						<li *ngFor="let onlineChannel of store.onlineChannels" class="inner-item onlineChannel">
							<a [href]="onlineChannel.url" target="_blank" class="value is-link">
								{{onlineChannel.type.ko}}
							</a>
						</li>
					</ul>
				</li>

				<!-- 대표메뉴 (optional)-->
				<li *ngIf="store.menuList.length" class="store-info-item">
					<figure class="icon-container" aria-hidden="true">
						<img class="icon" src="/assets/images/ui/icons/menuList@2x.png" alt="" />
					</figure>
					<span class="is-visually-hidden">가게 대표 메뉴</span>

					<ul class="menu-list">
						<li *ngFor="let menu of store.menuList" class="menu-item is-leaderdot">
							<span class="menu-name">{{ menu.name }}</span>
							<span class="menu-price">{{ toComma(menu.price) }}원</span>
						</li>
					</ul>
				</li>


				<!-- 알림 정보 -->
				<li class="store-info-item" *ngIf="store.notes.length">
					<span class="is-visually-hidden">가게 알림</span>
					<!-- <h3>가게 알림</h3> -->
					<ul class="note-list">
						<li class="note-item" *ngFor="let note of store.notes">{{note.note}}</li>
					</ul>
				</li>
			</ul>
		</div> <!-- .store-info -->

		<!-- 접근성 정보 영역-->
		<div class="store-info" *ngIf="store.a11yOptions">
			<div class="store-info-header"
				[class.is-layoutTypeList]="layoutType === 'list'">
				<h3>접근성</h3>
			</div>

			<!-- a11y list -->
			<ul class="store-info-list is-a11y-list">
				<ng-container 
					*ngFor="let a11yOption of store.a11yOptions; let i = index;">
					<!-- <mat-divider mat-divider *ngIf="i !== 0"  aria-hidden="true"></mat-divider> -->
					<!-- <mat-divider *ngIf="i === 0" aria-hidden="true"></mat-divider> -->
					<li *ngIf="a11yOption.state"
						class="store-info-item"
						[class.is-two_line]="a11yOption.moreInfos">
						
						<figure class="icon-container is-a11y" 
							aria-hidden="true">
							<img class="icon" 
								[src]="'/assets/images/ui/icons/a11y/stroke/'+ a11yOption.name_en + '@2x.png'"
								alt="">
						</figure>
	
						<div class="a11y-text-container">
							<p class="a11y-name">{{_getA11yOptionName(a11yOption)}}</p>
							<ul class="a11y-moreInfo" 
								*ngIf="a11yOption.moreInfos">
								
								<li *ngFor="let moreInfo of a11yOption.moreInfos">
									{{moreInfo.text}}
								</li>
								
								
								<!-- 내부에 휠체어 접근 가능한 장애인 화장실이 있으며, 화장실 내부에 안전 손잡이가 있습니다. -->
							</ul>
						</div>
					</li> <!-- .store-info-item -->
					<!-- <mat-divider aria-hidden="true"></mat-divider> -->
				</ng-container>
				
				

			</ul> <!-- .store-info-list.is-a11y-list -->


		</div>

	</div>
</section>