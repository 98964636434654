import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ENTER, COMMA, P } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';

// import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFirestore } from '@angular/fire/firestore';



import { Store, A11yOption, VALIDATOR_TEXT_LENGTH, CATEGORIES, ONLINE_CHANNELS, AccessibleInfo } from 'src/app/core/model';
import * as _ from 'lodash';
import { A11Y_OPTIONS } from '../../core/model';
import { getA11yOptionName } from 'src/app/utils/getA11yOptionName';



@Component({
  selector: 'app-add-store',
  templateUrl: './add-store.component.html',
  styleUrls: ['./add-store.component.scss']
})
export class AddStoreComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  

  accessibleOptions: A11yOption[];


  // 2023 devonly - start -
  isSubFormInit = false;

  // 2023 deonly - end - 
  

  // tags
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes = [ENTER, COMMA];


  validTextLength = VALIDATOR_TEXT_LENGTH;
  categories = CATEGORIES;
  onlineChannels = ONLINE_CHANNELS;



  // 가게 주소로 위경도 얻기
  kakao;  // window['daum']
  geocoder; // for get latlng value with addr

  constructor(
    public snackBar: MatSnackBar,
    public matDialog: MatDialog,
    private appService: AppService,
    private afs: AngularFirestore,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {
    this.accessibleOptions = _.map(A11Y_OPTIONS);

    

    // dev only - end
    this.initEachForms();
  }

  ngOnInit() {
    this.initKakaoGeoCoder();
  }
  ngOnDestroy() { }

  /**
   * a11yOptions 개선
   * 1) editComponent 흐름으로 작업한다.
   * - addContent()에서 form의 값을 바로 사용함 (onlinechannels)
   * 
   */





  // init kakao geocoder (for get lnglat by address)
  initKakaoGeoCoder() {
    if (window['daum']) {
      this.kakao = window['daum'];
      this.geocoder = new this.kakao.maps.services.Geocoder();
      console.log('init kakao gecoder');
    } else {
      console.error('kakaoMap SDK is not founded.');
    }
  }

  // 가게 주소로 위경도 값을 얻음 (with kakao geocoder)
  getLngLatByAddr(storeAddr: string) {

    const currentTypedAddr = this.form.value['addr'];
    
    // addr: this.form.get('addr').value,
    console.log(currentTypedAddr);

    // 위경도값이 입력되지 않은 경우에만 실행
    // geocoder 상태가 참인 경우
    if (this.geocoder) {
      // Kakao Geocoder에 위경도 요청
      this.geocoder.addressSearch(storeAddr, (result, status) => {
        
        if (status === this.kakao.maps.services.Status.OK) {
          // 정상 동작 시 store 위경도 업데이트 
          this.form.get('lat').patchValue(result[0].y);
          this.form.get('lng').patchValue(result[0].x);

          // store.lat = result[0].y;
          // store.lng = result[0].x;
        } else {
          // 검색 안될 경우, 오류 메시지 반환
          // ==> 잘못된 주소라면 modal 구성 필요
          console.error(`get geocode by addr is not working, ${storeAddr}`);
        }

      });
    }

  }




  initEachForms() {
    this.form = this.fb.group({
      isPublished: new FormControl(false, [Validators.required]),
      name: new FormControl('가게이름', [
        Validators.required,
        Validators.minLength(this.validTextLength.name.min),
        Validators.maxLength(this.validTextLength.name.max),
      ]),
      keySentence: new FormControl('한줄 소개', [
        Validators.required,
        Validators.minLength(this.validTextLength.keySentence.min),
        Validators.maxLength(this.validTextLength.keySentence.max),
      ]),

      summary: new FormControl(`가게 소개`, [
        Validators.required,
        Validators.minLength(this.validTextLength.summary.min),
        Validators.maxLength(this.validTextLength.summary.max),
      ]),
      category: new FormControl(null, [Validators.required]),
      // category_en: [null, [Validators.required]], // selection으로 자동 값 생성

      addr: new FormControl(null, [Validators.required]),

      lat: new FormControl(null),
      lng: new FormControl(null),

      // notes: this.fb.array([ this.initSubItems('notes') ]),
      notes: this.fb.array([]),

      openingInfos: this.fb.array([]),
      phone: new FormControl(null),
      onlineChannels: this.fb.array([]),
      menuList: this.fb.array([]),

      imgContentLink: new FormControl(null),

      a11yOptions: this.fb.array([]),
      tags: new FormControl([]),

    });
    this.setA11yOptions();

    
  } // initEachForms()


  setA11yOptions() {
    let control = this.form.controls.a11yOptions as FormArray;
    console.log('control', control);
    _.forEach(this.accessibleOptions, (a11yOption: A11yOption) => {
      control.push(this.fb.group({
        name: a11yOption.name,
        name_en: a11yOption.name_en,
        state: a11yOption.state,
        moreInfos: this.setMoreInfos(a11yOption)
      }));
    });
    console.log('control after ForEach', control);
    this.isSubFormInit = true;
  }

  setMoreInfos(a11yOption: A11yOption) {
    let arr = new FormArray([]);
    _.forEach(a11yOption.moreInfos, (moreInfo) => {
      arr.push(this.fb.group({ text: moreInfo.text }));
    });
    return arr;
  }


  addNewMoreInfoText(control) {
    control.push(
      this.fb.group({
        text: ['']
      }))
  }

  deleteMoreInfoText(control, index) {
    control.removeAt(index)
  }




  /**
   * - [] 전체 접근성 항목 form을 데이터로 만들기
   *  1) initA11yOptionFormGroups() // 전체 옵션으로 FormGroup 동적 생성
   *  2) initA11yOptionSubForms()   // 각 접근성 옵션 form 하위 moreinfo 데이터 생성
   * 
   * - [] 데이터로 만든 후, state 와 별도로 fromArray로 moreInfo만들기
   *  
   */


  




  
  

  // FormArray 항목 초기화
  initSubItems(controlName: string) {
    console.log('initSubItems', controlName);

    switch (controlName) {
      case 'notes':
        return this.fb.group({
          note: this.fb.control(null, Validators.maxLength(this.validTextLength.notes.max)),
          trackingId: _.uniqueId()
        });
      case 'openingInfos':
          return this.fb.group({
            day: this.fb.control(null, Validators.required),
            open: this.fb.control(null, Validators.required),
            close: this.fb.control(null, Validators.required),
            trackingId: _.uniqueId()
          });
      case 'onlineChannels':
        return this.fb.group({
          type: this.fb.control(null, Validators.required),
          url: this.fb.control(null, Validators.required),
          trackingId: _.uniqueId()
        });
      case 'menuList':
        return this.fb.group({
          name: this.fb.control(null, Validators.required),
          price: this.fb.control(null, [
            Validators.required,
            Validators.pattern("^[0-9]*$"),
          ]),
          trackingId: _.uniqueId()
        });
      
      default:
        console.error('initSubItems controlName is undefined');
        break;
    }
  }



  
  


  

  



  // FormArray에 추가
  addGroup(ctrlName: string) {
    const control = this.form.controls[ctrlName] as UntypedFormArray;
    control.push(this.initSubItems(ctrlName));
  }
  // FormArray에서 삭제
  removeGroup(i: number, controlName: string) {
    const control = this.form.controls[controlName] as UntypedFormArray;
    control.removeAt(i);
  }

  createField() {
    console.log('createdField!');
    const createdAt = new Date();
    const selectedCategory = this.form.get('category').value;

    const newStore: Store = {
      id: this.afs.createId(),
      isPublished: this.form.get('isPublished').value,


      keySentence: this.form.get('keySentence').value,
      name: this.form.get('name').value,
      category: selectedCategory,

      addr: this.form.get('addr').value,
      lat: this.form.get('lat').value,
      lng: this.form.get('lng').value,
      summary: this.form.get('summary').value,
      notes: this.form.get('notes').value,

      openingInfos: this.form.get('openingInfos').value,
      onlineChannels: this.form.get('onlineChannels').value,
      phone: this.form.get('phone').value,

      // mainImg: this.form.get('mainImg').value,
      // imgList: this.form.get('imgList').value,
      menuList: this.form.get('menuList').value,
      imgContentLink: this.form.get('imgContentLink').value,

      // accessibleOptions: this.accessibleOptions,
      a11yOptions: this.form.get('a11yOptions').value,
      tags: this.form.get('tags').value,
      createdAt,
      updatedAt: createdAt
    };


    




    this.appService.addDoc('stores', newStore)
      .then( () => {
        const routeDelay = 1400; // 완료 후 페이지 전환 딜레이

        // 여기에서 위경도 얻고 update

        // this.appService.setDocId('stores', newStore.createdAt);
        this.snackBar.open('저장 완료', '', { duration: routeDelay });
        setTimeout(() => { this.gotoPage('admin'); }, routeDelay);
      })
      .catch(err => console.error(err));
  }


  gotoPage(path: string) {
    this.router.navigate([path]);
  }

  


  addTag(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;
    const currentArr = this.form.get('tags').value;

    if (value) {
      const convertedValue = _.toLower(value);
      // console.log('convertedValue', convertedValue);
      const updatedArr = _.concat(currentArr, convertedValue.replace(/ /g, ''));
      this.form.get('tags').patchValue(updatedArr);
    }
    // Reset the Input value
    if (input) { input.value = ''; }
  }
  removeTag(tag: any) {
   //  console.log('removeTag', tag);
    const targetArr = this.form.get('tags');
    const beforeArr = targetArr.value;
    const afterArr = _.pull(beforeArr, tag);
    targetArr.patchValue(afterArr);
 }

 trackByOption(index: number, item: A11yOption) {
  return item.name;
}
trackByFn(index: number, item: any) {
  return item.trackingId;
}

checkFormValue() {
  console.log('현재 폼 값', this.form.value);
}



_getA11yOptionName(a11yOpton: A11yOption) {
  console.log(a11yOpton);
  return getA11yOptionName(a11yOpton);
}



}
