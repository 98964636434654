import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StoresComponent } from './stores/stores.component';
import { AboutComponent } from './about/about.component';
import { AboutEnComponent } from './about/about-en.component';

import { AdminGuard } from './core/admin.guard';
import { LoginComponent } from './core/login/login.component';
import { PromiseComponent } from './promise/promise.component';


const routes: Routes = [
  { path: '', redirectTo: '/stores', pathMatch: 'full' },
  { path: 'jeju', redirectTo: '/stores/jeju', pathMatch: 'full' },
  { path: 'seoul', redirectTo: '/stores/seoul', pathMatch: 'full' },
  
  { path: 'stores/:regionName', component: StoresComponent },

  { path: 'about', component: AboutComponent },
  { path: 'about-en', component: AboutEnComponent },

  { path: 'promise', component: PromiseComponent },

  // { path: 'admin', component: AdminComponent, canActivate: [AdminGuard] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard]},
  { path: 'admin/add-store', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard] },
  { path: 'admin/edit-store/:id', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard] },


  // { path: 'admin-store', component: AdminStoreComponent, canActivate: [AdminGuard] },
  // { path: 'add-store', component: AddStoreComponent, canActivate: [AdminGuard] },
  // { path: 'edit-store/:id', component: EditStoreComponent, canActivate: [AdminGuard] },

  { path: 'login', component: LoginComponent },
  { path: '**', component: StoresComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, 
      { 
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        // useHash: true
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
