<section class="store-preview">
	<div class="store-preview-header is-sticky-header"
		[class.is-expanded]="isExpanded">
		<div class="title-container">
			<p class="store-category">
				{{(store.keySentence)? store.keySentence : store?.category.name }}
			</p>
			<h2 class="store-name">{{ store.name }}</h2>
		</div>
		<button class="btn-close is-icon" 
			mat-icon-button type="button" 
			aria-label="닫기" (click)="closeSheet()">
			<mat-icon>close</mat-icon>
		</button>
	</div>

	<div class="store-summary">
		<p>{{ store.summary }}</p>
	</div>
	<div class="store-preview-footer">
		<button *ngIf="!isExpanded" 
			class="btn-viewmore"
			[attr.aria-expanded]="false"
			[attr.aria-controls]="'store-detail-wrapper'"
			mat-button
			type="button"
			(click)="toggleExpandState()">
			자세히 보기
		</button>
	</div>
	<div id="store-detail-wrapper"
		*ngIf="isExpanded">
		<app-store-detail 
			[store]="store"
			[layoutType]="'map'"></app-store-detail>
	</div>
	
	
</section>
