<section class="dialog-container">
        <h2 mat-dialog-title>
                <span class="dialog-title">콘텐츠 삭제</span>
                <button mat-icon-button 
                        type="button"
                        mat-dialog-close
                        aria-label="팝업창 닫기">
                        <mat-icon>close</mat-icon>
                </button>
                <!-- <button class="btn-close"
                        type="button"
                        mat-dialog-close>
                        <img src="assets/images/ui/btn-close@2x.png" alt="">
                </button>      -->
        </h2>
        <mat-dialog-content class="is-delete-content">
                <div class="warnning-row">
                        <mat-icon class="icon-warn">info</mat-icon>
                        <p class="msg">여기서 삭제 버튼을누르면 데이터베이스에서 완전히 삭제되며, 복구 할 수 없어요.</p>
                </div>
                <div class="form-row">
                        <div class="current-doc-info">
                                <span class="current-doc-info-title">현재 문서 : </span>
                                <span class="current-doc-info-value">
                                        <ng-container *ngIf="data">{{data.name}}</ng-container>
                                </span>
                        </div>
                </div>
        </mat-dialog-content>
        <mat-dialog-actions class="mat-dialog-actions">
                <span></span>
                <div class="btn-container">
                        <button mat-button mat-dialog-close>취소</button>
                        <button color="warn" mat-flat-button (click)="deleteField()">삭제</button>
                </div>

        </mat-dialog-actions>

</section>