import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';


@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.authService.user.pipe(
        take(1),
        map(user => {
          // console.log(user);
          return user && user.roles.admin ? true : false;
        }),
        tap(isAdmin => {
          if (!isAdmin) {
            console.error('Access denied - Admins only');
            this.router.navigate(['/login']);
          } else {
            console.log('admin access complete.');
          }
        })
      );
  }
}
