import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about.component';
import { AboutEnComponent } from './about-en.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
export const ABOUT_COMPONENTS = [
  AboutComponent,
  AboutEnComponent
];



@NgModule({
  declarations: [
    ABOUT_COMPONENTS
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    MatButtonModule
  ]
})
export class AboutModule { }
