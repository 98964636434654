import { Component, OnInit, Input, HostListener } from "@angular/core";

import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { StoreBottomsheetComponent } from "../store-bottomsheet/store-bottomsheet.component";
import { AppService } from "src/app/app.service";
import { Store } from "src/app/core/model";

import * as _ from "lodash";

// Mapbox
import { Map, LngLat, LngLatBounds } from "mapbox-gl";
import * as mapboxgl from "mapbox-gl";
import { ActivatedRoute, Router } from "@angular/router";
import { getWordWithRegex } from "../../utils/regex";

export interface MapConfig {
  width: string;
  height: string;
  center: Array<any>; // lat, lng
  zoomLev: number;
}

export interface Region {
  center: any;
  zoomLev: ZoomLev;
}

export class Regions {
  jeju: Region;
  seoul: Region;
}

export interface ZoomLev {
  mobile: number;
  tablet: number;

  desktop?: number;
  widescreen?: number;
}

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  @Input() stores: Array<Store>;

  // Mapbox ver //
  mapboxMap: Map;
  _mapRef: Map;
  _center: LngLat;

  // map ux configuration
  _config = {
    zoomLev: {
      default: 6, // 서울, 제주 보이는 전국 뷰
      storeDetail: 12, // 마커 클릭 및 fragment 입력 시 보이는 뷰
      storeDetail_max: 15, // legacy
    },
    dur: {
      zoomTo: {
        default: 300,
        short: 200, // panTo only
        long: 500,
      },
    },
  };

  // bounds: LngLatBounds; // for fit to bounds

  fragment: string;

  regions: Regions = {
    jeju: {
      center: { lat: 33.51042191055153, lng: 126.51403292005858 },
      zoomLev: { mobile: 7.8, tablet: 9 },
    },
    seoul: {
      center: { lat: 37.5746, lng: 126.9683 },
      zoomLev: { mobile: 10.6, tablet: 11.4 },
    },
  };

  currentRegion: string;
  mapConfig: MapConfig;

  kakao; // window['daum']
  map; // map instance
  geocoder; // for get latlng value with addr

  //
  vw;
  vh;
  deviceType;

  constructor(
    private bottomSheet: MatBottomSheet,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.getScreenSize();
  }

  ngOnInit() {
    // v2
    this.subUrlFragment();
    this.subUrlParams();
  }

  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    // viewport 구하기
    const vw = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    const vh = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    if (vw) {
      this.vw = vw;
      this.vh = vh;
      this.deviceType = this.vw < 769 ? "mobile" : "tablet";
      // console.log('this.deviceType', this.deviceType);
    } else {
      console.error("vw check is not working.😂");
    }
  }

  subUrlFragment() {
    // console.log('subUrlFragment');
    // Sub: fragment (항상 sub하지만, fragment 없으면 별다른 실행 없음)
    this.activatedRoute.fragment.subscribe((fragment) => {
      // fragment가 있는 경우
      if (fragment) {
        console.log("fragment", fragment);
        // 1) skipLink fragement (#btn-store-list)
        // 2) store name fragment (#아살람)

        // 1) skipLink fragement (#btn-store-list)
        if (fragment === "btn-view_list") {
        } else {
          // 2) store name fragment (#아살람)
          this.fragment = fragment;

          // fragment 공백, 특수문자 제거
          const convertedFragment = getWordWithRegex(fragment);

          // 이름과 매칭되는 Store 찾기 (by fragment)
          const matchedStore = _.find(this.stores, function (store: Store) {
            const convertedStoreName = getWordWithRegex(store.name);
            return convertedStoreName === convertedFragment;
          });

          // matchedStore 있을 경우 mapConfig init
          if (matchedStore) {
            // set map view (by selected Store - center, zoomLev)
            this.mapConfig = {
              width: `100vw`,
              height: `100vh`,
              center: [matchedStore.lng, matchedStore.lat],
              zoomLev: this._config.zoomLev.storeDetail,
            };

            // 2. open selected Store bottomSheet
            this.bottomSheet.open(StoreBottomsheetComponent, {
              data: matchedStore,
            });
          } else {
            // 입력된 fragment와 매칭되는 store 가 없음
            // 1. fragment init process break; (do not anything)
            console.error(
              "current fragment is not founded.",
              convertedFragment
            );
            // 2. current fragment reset (잘못된 fragment 이므로, reset)
            // - 뒤로가기 등의 동작에 영향을 미침
            this.fragment = null;
            // remove unmatched fragment (without direction)
            this.router.navigate([]);
          }
        }
      }
    });
  }

  // 지역 명(seoul, jeju)포함하는 경우 실행
  // fragment와 달리 '/' 를 포함하는 경우, 따로 sub 없이도 매번 redirect되므로, 매번 실행됨
  subUrlParams() {
    // console.log('subUrlParams!');

    let regionName;
    this.activatedRoute.params.forEach((urlParams) => {
      regionName = urlParams.regionName;
    });

    // fragment가 없는 경우 실행함
    // 그렇지 않으면 fragment 찾고 세팅하는 과정에서 지역 기준으로 표시됨 (개선 필요)
    if (!this.fragment) {
      // regionName 있음
      if (regionName) {
        // isMatched? (올바른 regionName?)
        if (_.hasIn(this.regions, regionName)) {
          this.onRegion(regionName);
        } else {
          // 잘못된 regionName 입력
          console.error("current regionName is not founded.");
          // param clean here!
          this.router.navigate([""]);
        }
      } else {
        // regionName 없을 경우, 기본 region 설정
        this.onRegion("seoul");
      }
    }
  }

  onRegion(regionName: string) {
    // console.log('onRegion', regionName);
    // add ga here
    // 현재 선택된 지역과 다른 경우에 시만 실행
    if (this.currentRegion !== regionName) {
      this.currentRegion = regionName;
      const zoomLev =
        this.deviceType === "mobile"
          ? this.regions[regionName].zoomLev.mobile
          : this.regions[regionName].zoomLev.tablet;

      this.mapConfig = {
        width: `100vw`,
        height: `100vh`,
        center: [
          this.regions[regionName].center.lng,
          this.regions[regionName].center.lat,
        ],
        zoomLev: zoomLev,
      };
    } else {
      console.log("regionName is same before");
    }
  }

  // mapbox
  onLoadMap(map: Map) {
    // console.log('onLoadMap', map);
    this._mapRef = map;
  }

  /**
   * init 
   * @Input() stores: Array<Store>
   * check Url Params *only activated have param.
    * 1) fragment
      * (matchedStore)
        ? mapConfig.center, zoomLev
        : break;
    * 2) regionName
      * (isMatch)? init : break;  
    * 3) default init
   */

  // 지역 url param
  /** MapConfig init with RegionName (By UrlParam)
   * /stores -> seoul map (center, zoomLev)
   * /stores/seoul -> (위와 동일)
   * /stores/jeju -> jeju map (center, zoomLev)
   */
  getRegionTypeByUrlParam() {
    let regionName;
    this.activatedRoute.params.forEach((urlParams) => {
      // console.log('urlParams', urlParams);
      regionName = urlParams.regionName;
    });

    if (regionName && _.hasIn(this.regions, regionName)) {
      this.onRegion(regionName);
    } else {
      this.router.navigate(["/stores"]);
      // this.onRegion('seoul');
    }
  }

  // Marker Click
  onMarker(store: Store) {
    // console.log('onMarker', store);

    // 해당 마커 위치로 이동
    this.zoomTo(store);
    const bottomSheetRef = this.bottomSheet.open(StoreBottomsheetComponent, {
      data: store,
    });
  }

  // issue: zoomTo 하면 크롬 무한로딩
  zoomTo(store: Store) {
    console.log("zoomTo", store.name);
    // console.log('this._mapRef', this._mapRef); // undefined.

    /** zoomLev
     * 15 : store detail : 가게 상세 뷰
     * 12 : store detail default : 가게 지역 뷰
     * n : ---
     * 6 : 서울, 제주 보이는 전국 뷰
     */
    let afterZoomLev = this._config.zoomLev.storeDetail;
    const currentZoomLev = this._mapRef.getZoom();
    let easeToDur;

    // 현재 줌 레벨이 목표 줌 레벨보다 더 높은 경우(확대된 경우) 줌을 그대로 유지한다.
    if (currentZoomLev >= afterZoomLev) {
      afterZoomLev = currentZoomLev;
      easeToDur = this._config.dur.zoomTo.short;
    } else {
      // zoomLev 변경이 있는 경우

      // zoomGap before <-> after 차이
      const zoomGap = afterZoomLev - currentZoomLev;
      // zoomGap이 크지 않은 경우 ( <= 3.5)
      if (zoomGap <= 3.5) {
        easeToDur = this._config.dur.zoomTo.default;
      } else {
        // zoomGap이 큰 경우
        easeToDur = this._config.dur.zoomTo.long;
      }
      // console.log(`zoomGap: ${zoomGap} easeToDur: ${easeToDur}`);
    }

    // this._mapRef.jumpTo({
    //   center: [store.lng, store.lat],
    //   zoom: afterZoomLev,
    // });

    this._mapRef.easeTo({
      center: [store.lng, store.lat],
      zoom: afterZoomLev,
      duration: easeToDur,
      easing(t) {
        return t;
      },
    });
  }

  onZoom(e: any) {
    // const currentZoomLev = Math.round(this._mapRef.getZoom());
    const currentZoomLev = this._mapRef.getZoom();

    console.log("onZoom() currentZoomLev", currentZoomLev);
    console.log(`center: ${this._mapRef.getCenter()}`);
  }

  setZoomLev(dir: string) {
    const currentZoomLev = this._mapRef.getZoom();
    const updateZoomLev =
      dir === "in" ? currentZoomLev - 1 : currentZoomLev + 1;

    this._mapRef.setZoom(updateZoomLev);
  }
}
