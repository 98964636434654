
export function toCommaString(currency: number) {
        const numberString = currency.toString();
        const numberParts = numberString.split('.');
        const regexp = /\B(?=(\d{3})+(?!\d))/g;
        if (numberParts.length > 1) { 
                return numberParts[0].replace( regexp, ',' ) + '.' + numberParts[1];
        } else {
                return numberString.replace( regexp, ',' );
        }
}
