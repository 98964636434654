
import { A11yOption, A11Y_OPTIONS } from '../core/model';
import * as _ from 'lodash';
  // a11yOption의 영문명 기준으로 한글 옵션이름 표시
export function getA11yOptionName(a11yOption: A11yOption) {
	const matchedOption = _.find(A11Y_OPTIONS, { 'name_en': a11yOption.name_en } );
	if (matchedOption) {
		// console.log('matchedOption', matchedOption.name);
		return matchedOption.name;
	} else {
		console.error('matchedOption is not founded.', a11yOption.name_en);
		return a11yOption.name;
	}
}