<section class="store-list-container">
	<!-- 상단 고정 헤더 -->
	<div class="store-list-header is-sticky-header">
		<h2>참여가게 목록</h2>

		<!-- mat-icon-button  -->
		<button class="btn-close is-text" 
			type="button"
			mat-button
			(click)="closeSheet()">
			닫기
			<!-- <mat-icon>close</mat-icon> -->
		</button>
	</div>

	<p class="store-count">현재 {{stores.length}}개 가게가 참여하고 있어요.</p>
	
	<ul class="store-list">
		<ng-container *ngFor="let store of stores; let i = index;">
			<!-- <mat-divider *ngIf="i == 0" ></mat-divider> -->
			<li class="store-item">
				<mat-expansion-panel 
					class="mat-elevation-z0" hideToggle>
					<!-- [@.disabled]="true" -->
					<mat-expansion-panel-header
						(click)="onExpansionHeader(store)">
						<div class="header-row">
							
							<div class="header-texts">
								<p class="store-category">
									{{(store.keySentence)? store.keySentence : store?.category.name }}
								</p>
								<h3 class="store-name">{{store.name}}</h3>
							</div>
							<figure class="header-icon-container" aria-hidden="true">
								<img *ngIf="!store.isExpanded" src="/assets/images/ui/icons/btn-expand@2x.png" alt="" class="icon">
								<img *ngIf="store.isExpanded" src="/assets/images/ui/icons/btn-collapse@2x.png" alt="" class="icon">

							</figure>
							
						</div>
						
						<mat-panel-description aria-hidden="true">
							<p class="store-summary">{{store.summary}}</p>
						</mat-panel-description>
						
					</mat-expansion-panel-header>


					<ng-template matExpansionPanelContent>
						<p class="store-summary is-visually-hidden">{{store.summary}}</p>
						<div class="store-detail-wrapper">
							<app-store-detail 
								[store]="store"
								[layoutType]="'list'"></app-store-detail>
						</div>
						
					</ng-template>
				  </mat-expansion-panel>
			</li>
			<!-- <mat-divider aria-hidden="true"></mat-divider> -->


		</ng-container>



	</ul>


</section>
<!-- legacy -->