<section class="hero" aria-hidden="true">
	<img src="assets/images/about/hero/en@2x.png" class="hero-img" alt="">
	<!-- <svg-icon [svgUrl]="assets/images/about/mobile/hero/graphic.svg" [svgStyle]="svgStyle"></svg-icon> -->
</section>

<section class="about">
	<!-- 장애인에게 열려있는 공간이 모두에게 열린 공간 -->
	<!-- <p>차별없는가게</p> -->
	<p>
		"Project We Welcome All" is a mapping project to map the neighborhood diners or cafes that promise to accept social minorities as equal individuals and not be discriminated in communities.
	</p>

	<p>
		The project aims to increase spaces that will not reject people or make them feel uneasy because of their identities
		such as disabilities.
	</p>

	<!-- 디자인을 위한 이미지라서 alt 내용 없이 구성-->
	<img class="about-graphic-middle" src="assets/images/about/graphic-middle@2x.png" alt="" />




	<p>
		Nevertheless, Project We Welcome All is not designed to censor or approve places for perfectly discrimination-free
		space.
	</p>


	<p>
		Rather, the project attempts to make an impossible promise to understand and respect the unfamiliar other with
		whatever effort for the possibilities and the process.
	</p>
	<p>
		We may still be imperfect beings to one another, but the promise not to discriminate may bring us closer to one
		another’s world.
	</p>


	<div class="video-container is-en" style="padding:56.25% 0 0 0; position:relative;">
		<iframe title="we welcome all project introduction animation"
			src="https://player.vimeo.com/video/383456752?autoplay=1&title=0&byline=0&portrait=0"
			style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allowfullscreen>
		</iframe>
	</div>





</section>

<section class="about">
	<h2>
		<img src="assets/images/about/code-of-conduct/en@3x.png" alt="code of conduct" class="about-promissory" />
	</h2>

	<ul class="about-sentence">
		<li *ngFor="let sentence of sentences">
			{{sentence}}
		</li>
	</ul>
</section>


<section class="about project-pdf">
	<h2><a href="https://drive.google.com/drive/folders/1tYW8Nj6VXhRd1bx-FrYxr8MIAwI94QdO?usp=sharing" target="_blank"
			mat-stroked-button>Project We Welcome All PDF (new window)</a></h2>
</section>