import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";

import { map, tap, shareReplay, take } from "rxjs/operators";

import { Store } from "./core/model";
import * as _ from "lodash";
import { BehaviorSubject, Observable } from "rxjs";
import { Language } from './app.component';
@Injectable({
  providedIn: "root",
})
export class AppService {
	// BehaviorSubject emit 이후 구독하더라도 마지막 emit 값 얻을 수 있음.
  private currentLang$ = new BehaviorSubject<Language>({ value: "ko", name: "한국어" });

  stores: AngularFirestoreCollection<Store>;
  storesAdmin: AngularFirestoreCollection<Store>;
  // for about language change

  constructor(private afs: AngularFirestore) {
    // this.stores = this.afs.collection<Store>("stores");
    this.stores = this.afs.collection<Store>('stores', ref =>  ref.where('isPublished', '==', true));
    this.storesAdmin = this.afs.collection<Store>("stores");
    //   this.links = this.afs.collection<Link>('links', ref => ref.orderBy('createdAt', 'asc'));
  }


  // Subject
  updateCurrenLang$(lang: Language) {
    this.currentLang$.next(lang);
  }

  getCurrentLang$(): Observable<any> {
    return this.currentLang$.asObservable();
  }


  // Firestore
  getDocById(colName: string, id: any) {
    const path = `${colName}/${id}`;
    return this.afs.doc<any>(path);
  }

  // Stores
  getStores() {
    return this.stores.valueChanges().pipe(
      tap((arr) => { 
        // console.log(`read ${arr.length} store docs`); 
      }),
      shareReplay(1)
    );
  }

  // Stores for Dev
  getStoresAdmin() {
    return this.storesAdmin.valueChanges().pipe(
      tap((arr) => console.log(`read ${arr.length} store Admin docs`)),
      shareReplay(1)
    );
  }

  getStoresSnapshot() {
    return this.stores.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          return { id: a.payload.doc.id, ...a.payload.doc.data() };
        });
      })
    );
  }

  //// CRUD ////

  addDoc(category: string, data: any) {
    switch (category) {
      case "stores":
        // 2019 ver
        //     const newStore: Store = data;
        //     return this.storesAdmin.add(newStore);
        return this.storesAdmin.doc(data.id).set(data);
      default:
        console.error("msg: addDoc this category is undefined.");
        break;
    }
  }
  updateDoc(category: string, id: string, data: any) {
    data.updatedAt = new Date();
    switch (category) {
      case "stores":
        const newStore: Store = data;
        return this.getDocById(category, id).update(newStore);
      default:
        console.error("msg: updateDoc this category is undefined.");
        break;
    }
  }

  deleteDoc(category: string, id: string) {
    switch (category) {
      case "stores":
        return this.getDocById(category, id).delete();
      default:
        console.error("msg: deleteDoc this category is undefined.");
        break;
    }
  }

  setDocWithMerge(category: string, id: string, data: any) {
    switch (category) {
      case "stores":
        const newStore: Store = data;
        return this.getDocById(category, id).set(newStore, { merge: true });
      default:
        console.error("msg: setDocWithMerge this category is undefined.");
        break;
    }
  }

  // id update method
  // setDocId(category: string, dateInfo: Date) {
  //         const addedDocRef = this.afs.collection(category, ref => ref.where('createdAt', '==', dateInfo));

  //         if (addedDocRef) {
  //                 const docId = addedDocRef
  //                 .snapshotChanges()
  //                 .pipe(
  //                         map(actions => {
  //                                 return actions.map(a => {
  //                                         return { id: a.payload.doc.id, ...a.payload.doc.data() };
  //                         });
  //                 })
  //                 ).pipe(
  //                         take(1)
  //                 ).subscribe(docs => {
  //                         docs.forEach(doc => {
  //                         // id update at once (1회만 업데이트, 무한반복x)
  //                                 this.getDocById(category, doc['id']).set({ id: doc['id'] }, { merge: true });
  //                         });
  //                 });
  //         }
  // }
}
