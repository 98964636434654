import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminGuard } from '../core/admin.guard';
import { AdminComponent } from './admin.component';
import { AddStoreComponent } from './add-store/add-store.component';
import { EditStoreComponent } from './edit-store/edit-store.component';


const routes: Routes = [
        { path: '', component: AdminComponent, canActivate: [AdminGuard] },
        { path: 'add-store', component: AddStoreComponent, canActivate: [AdminGuard] },
        { path: 'edit-store/:id', component: EditStoreComponent, canActivate: [AdminGuard] },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)]
})
export class AdminRoutingModule { }
