import { Component, OnInit, Input } from '@angular/core';
import { OnlineChannel } from 'src/app/core/model';

@Component({
  selector: 'app-icon-sns',
  templateUrl: './icon-sns.component.html',
  styleUrls: ['./icon-sns.component.scss']
})
export class IconSnsComponent implements OnInit {
  @Input() onlineChannel: any;

  constructor() { }

  ngOnInit() {
  }

  convertChannelName(channelType: string) {
    switch (channelType) {
      case 'facebook':
        return '페이스북';
      case 'instagram':
        return '인스타그램';
      case 'twitter':
        return '트위터';
      case 'youtube':
        return '유튜브';
      case 'website':
        return '웹사이트';
      default:
        console.error('convertChannelName is not working!');
        return '온라인채널';
    }
  }

  openWindow(onlineChannel: OnlineChannel) {
    let fullUrl;

    switch (onlineChannel.type) {
      case 'facebook':
        fullUrl = 'https://www.facebook.com/' + onlineChannel.url;
        break;
      case 'instagram':
        fullUrl = 'https://www.instagram.com/' + onlineChannel.url;
        break;
      case 'twitter':
        fullUrl = 'https://www.twitter.com/' + onlineChannel.url;
        break;
      case 'youtube':
        fullUrl = 'https://www.youtube.com/' + onlineChannel.url;
        break;
      case 'website':
        fullUrl = onlineChannel.url;
        break;
      default:
        console.error('convertChannelName is not working!');
        fullUrl = onlineChannel.url;
        break;
    }
    window.open(fullUrl);
  }

}
