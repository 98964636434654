import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { Language } from '../app.component';


export interface Sentences {
  ko: Array<string>;
  en: Array<string>;
}


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  lang$: Subscription;
  lang: Language;

  // svgStye = {
  //   'width.px': 219,
  //   'height.px': 420,
  // };

  sentences: Sentences = {
    ko: [
      `상대방과 대화를 나눌 때는 상대방을 존중하는 마음을 갖도록 합니다.`,
      `사회적 소수자에 대한 혐오발언을 하지 않으며, 나이, 성별, 성정체성, 성적지향, 장애와 혼인 여부, 국적, 피부색, 출신 지역, 학력, 가족 관계, 직업 등을 이유로 차별하지 않습니다.`,
      `서로의 지식, 경험, 가치관, 취향을 존중하며, 누구든 스스로 선택할 기회를 제공받을 수 있다는 것을 기억합니다.`,
      `시혜나 동정을 할 대상이 아닌 나와 동등한 존재로 서로를 대합니다.`,
      `모르는 것, 불편한 것이 있으면 적극적으로 표현하고 함께 소통하며 대처합니다.`,
    ],
    en: [
      `We promise to always speak to others with respect.`,
      `We will not utter hate speech about social minorities, and will not discriminate others based on age, gender, gender identity, sexual orientation, disability, marital status,nationality, race, region of origin, education, family status, occupation, etc.`,
      `We respect each other’s knowledge, experience, values, and taste, and keep in mind that everyone’s freedom to make their own choices should be respected.`,
      `We will not patronize or pity them, but treat everyone as equal individuals.`,
      `When there are some inconveniences, or things that we do not understand,we will deal with them through active communication.`,
    ]
  };

  

  constructor(private appService: AppService,) { }

  ngOnInit() {
    this.subLanguage$();
  }
  ngOnDestroy(): void {
    if (this.lang$) {
      this.lang$.unsubscribe();
    }
  }


  subLanguage$() {
    this.appService
      .getCurrentLang$()
      .subscribe(lang => { 
        this.lang = lang;
      });
  }

  
}
