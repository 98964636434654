import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-en',
  templateUrl: './about-en.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutEnComponent implements OnInit {
  sentences = [
    '1. We promise to always speak to others with respect.',
    '2. We will not utter hate speech about social minorities, and will not discriminate others based on age, gender, gender identity, sexual orientation, disability, marital status,nationality, race, region of origin, education, family status, occupation, etc.',
    '3. We respect each other’s knowledge, experience, values, and taste, and keep in mind that everyone’s freedom to make their own choices should be respected.',
    '4. We will not patronize or pity them, but treat everyone as equal individuals.',
    '5. When there are some inconveniences, or things that we do not understand,we will deal with them through active communication.',
  ];

  constructor() { }

  ngOnInit() {
  }

}
