<section class="login" ngxUiLoaderBlurred blur="10">
        <div *ngIf="user; then isUser else notUser"></div>
        <!-- 비로그인 상태 -->
        <ng-template #notUser>
                <div class="login-container">
                <div class="login-title">차별없는가게</div>
                <div class="items">
                        <div class="item" >
                        <mat-form-field floatLabel="never">
                                <mat-icon matPrefix>mail</mat-icon>
                                <mat-label>이메일</mat-label>
                                <input type="email" matInput [(ngModel)]="email" placeholder="이메일 주소" >
                        </mat-form-field>
                        </div>
                        <div class="item">
                        <mat-form-field floatLabel="never">
                                <mat-icon matPrefix>lock_open</mat-icon>
                                <mat-label>비밀번호</mat-label>
                                <input matInput [(ngModel)]="password" type="password" [placeholder]="'비밀번호'" >
                        </mat-form-field>
                        </div>
                </div>

                <button class="btn-login" mat-button (click)="login()">로그인</button>
                <!--
                <div class="btn-row">
                        <button class="btn-backtohome" 
                        (click)="gotoPage('view')" mat-button>메인 페이지로</button>
                        <button class="btn-login" mat-stroked-button (click)="login()">로그인</button>
                </div>
                -->
                <div class="btn-row">
                        
                </div>
                </div>
        </ng-template>
        <!-- 로그인 상태 -->
        <ng-template #isUser>
                <div class="login-container">
                <div class="login-title">이미 관리자 로그인이 되어있어요 :)</div>
                <div class="btn-row">
                        <button class="btn-backtohome" 
                        (click)="gotoPage('stores')" mat-button>메인 페이지로</button>
                        <button class="btn-backtohome" 
                        (click)="gotoPage('admin')" mat-button>관리자 페이지로</button>
                </div>
                </div>
        </ng-template>
        
        
        
</section>