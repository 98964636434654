import { Component, Input } from '@angular/core';
import { Store } from 'src/app/core/model';
import { toCommaString } from '../../utils/toCommaString';
import { A11yOption, A11Y_OPTIONS } from '../../core/model';
import * as _ from 'lodash';
import { getA11yOptionName } from 'src/app/utils/getA11yOptionName';

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss']
})
export class StoreDetailComponent {
  @Input() store: Store;
  @Input() layoutType: string;


  a11yOptions: Array<A11yOption> = A11Y_OPTIONS;


  ngOnInit() {
    console.log('store-detail init!', this.store);
  }


  toComma(price: any) {
    return toCommaString(price);
  }

  _getA11yOptionName(a11yOpton: A11yOption) {
    return getA11yOptionName(a11yOpton);
  }


  btnDev(store: Store) {
    console.log('store', store);
  }


}
