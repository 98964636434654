import { Component, OnInit, Inject } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from 'src/app/core/model';

@Component({
  selector: 'app-delete-store',
  templateUrl: './delete-store.component.html',
  styleUrls: ['./delete-store.component.scss']
})
export class DeleteStoreComponent implements OnInit {

  constructor(
    private appService: AppService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DeleteStoreComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Store
  ) { }

  ngOnInit() {
  }

  deleteField() {
    console.log('delete field');
    this.appService.deleteDoc('stores', this.data.id)
      .then(() => {
        this.dialogRef.close();
        this.snackBar.open('삭제 완료', '');
      })
      .catch(err => console.error(err));
  }

}
