import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AppService } from 'src/app/app.service';

import { Store } from 'src/app/core/model';
import { Router } from '@angular/router';
import { DeleteStoreComponent } from './delete-store/delete-store.component';
import { AuthService } from '../core/auth.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, AfterViewInit {
  // @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public dataSource: MatTableDataSource<any> = new MatTableDataSource();


  // readonly dataSource: MatTableDataSource<any> = new MatTableDataSource();

  stores;

  // array 순서에따라 column 순서가 결정된다.
  displayedColumns = [
    'isPublished',
    'name',
    'category',
      'keySentence',
      'summary',
      'phone',
      'addr',
    'edit',
    'delete',
    'createdAt'
  ];

  stickyColumnName = ['isPublished', 'name'];
  
  constructor(
    private appService: AppService,
    private authService: AuthService,
    public matDialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit() { }
  ngAfterViewChecked(): void {
    // console.log('ngAfterViewChecked()');
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    if (this.table) {
      this.table.updateStickyColumnStyles();
    } else {
      console.error('this.table is not founded.');
    }
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit!');
    
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.appService.getStoresAdmin()
    .subscribe(stores => {
      console.log('getStores()', stores);
      this.stores = stores;
      this.dataSource.data = stores;
    });
  
  }

  

  initTableDataSource() {
    console.log('initTableDataSource!');
    if (this.stores) {
      this.dataSource = new MatTableDataSource(this.stores);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    } else {
      console.error('this.stores is undefined.');
    }
    
  }

  // 텍스트 입력 필터
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /** Whether the button toggle group contains the id as an active value. */
  isSticky(id: string) {
    // const state = (this.stickyColumnName).indexOf(id) !== -1;
    // console.log(`isStickiy(${id}) is ${state}`);

    return (this.stickyColumnName).indexOf(id) !== -1;
  }



  openDeleteDialog(store: Store) {
    const deleteDialogRef = this.matDialog.open(DeleteStoreComponent, {
      data: store
    });
    deleteDialogRef.afterClosed()
      .subscribe(msg => {
        if (msg === 'delete') {
          this.openSnackBar('콘텐츠 삭제 완료', '');
        } else {
          this.openSnackBar(msg, '');
        }
      });
  }

  // Admin Snackbar
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2500,
    });
  }

  trackByCreatedAt(index, item) {
    return item.createdAt;
  }

  gotoPage(path: string, store?: Store) {
    console.log('gotoPage', store);
    const link = (store)
      ? ['admin/edit-store', store.id]
      : ['admin/add-store'];

    this.router.navigate(link);
  }


  signOut() {
    this.authService.signOut();
  }

}
