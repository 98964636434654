import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-tag',
  templateUrl: './icon-tag.component.html',
  styleUrls: ['./icon-tag.component.scss']
})
export class IconTagComponent implements OnInit {
  @Input() category: any;

  constructor() { }

  ngOnInit() { }

}
