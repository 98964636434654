import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiModule } from './ui/ui.module';


import { StoresModule } from './stores/stores.module';

// import { NgxMapboxGLModule } from 'ngx-mapbox-gl';



import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { AdminModule } from './admin/admin.module';
import { CoreModule } from './core/core.module';
import { AppService } from './app.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment.prod';
import { AboutModule } from './about/about.module';
import { StoresDialogComponent } from './stores-dialog/stores-dialog.component';
import { PromiseModule } from './promise/promise.module';
import { RouterModule } from '@angular/router';

export const MAT_MODULES = [
  MatDividerModule,
  MatIconModule,
  MatButtonModule,
  MatInputModule,
  MatDialogModule,
  MatSelectModule
];


@NgModule({
    declarations: [
        AppComponent,
        StoresDialogComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        RouterModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        // AngularSvgIconModule,
        // NgxMapboxGLModule.withConfig({
        //   accessToken: 'pk.eyJ1IjoidGFka2ltIiwiYSI6ImNsYzBoMTNveDE0dngzcG1kb21ncHA4ZGQifQ.cX7gQXCwP6a4Xyr3Asmzpg', // Optional, can also be set per map (accessToken input of mgl-map)
        // }),
        MAT_MODULES,
        UiModule,
        StoresModule,
        AdminModule,
        CoreModule,
        AboutModule,
        PromiseModule
    ],
    providers: [AppService, AngularFirestore],
    bootstrap: [AppComponent]
})
export class AppModule { }
