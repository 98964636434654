import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppService } from '../app.service';
import { Store } from '../core/model';


@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit {
  stores: Array<Store>;

  constructor(
    private appService: AppService
  ) { }


  ngOnInit() {
    this.getStores();
  }

  getStores() {
    this.appService.getStores()
      .subscribe(stores => {
        this.stores = stores;
      });
  }





}
