import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoresComponent } from './stores.component';

import { KakaoMapsModule } from 'kakao-maps-sdk';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UiModule } from '../ui/ui.module';

import { MapComponent } from './map/map.component';
import { StoreBottomsheetComponent } from './store-bottomsheet/store-bottomsheet.component';

import { ListBottomsheetComponent } from './list-bottomsheet/list-bottomsheet.component';
import { StoresDialogComponent } from '../stores-dialog/stores-dialog.component';

import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { StoreDetailComponent } from './store-detail/store-detail.component';
import { CustomMatExpansionToggleDirective } from './custom-mat-expansion-toggle.directive';

export const STORES_COMPONENTS = [
  StoresComponent,
  MapComponent,
  StoreDetailComponent,
];

export const STORES_ENTRY_COMPONENTS = [
  StoreBottomsheetComponent,
  ListBottomsheetComponent,
];
export const STORES_MODULES = [
  MatCardModule,
  
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,

  MatDividerModule,
  MatListModule,
  MatIconModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatSnackBarModule,
  MatDialogModule
];

@NgModule({
    declarations: [
        STORES_COMPONENTS,
        STORES_ENTRY_COMPONENTS,
        CustomMatExpansionToggleDirective,  
    ],
    imports: [
        CommonModule,
        NgxMapboxGLModule.withConfig({
          accessToken: 'pk.eyJ1IjoidGFka2ltIiwiYSI6ImNsYzBoMTNveDE0dngzcG1kb21ncHA4ZGQifQ.cX7gQXCwP6a4Xyr3Asmzpg', // Optional, can also be set per map (accessToken input of mgl-map)
          // accessToken: 'pk.eyJ1Ijoic3R1Y2t5aXN0dWRpbyIsImEiOiJjbGM1eDY3N2oycnpoM29wajlyMTFyZXMyIn0.8qmnRPa5ejG1bHStTp-xiQ',
        }),
        STORES_MODULES,
        UiModule,
        KakaoMapsModule,
    ]
})
export class StoresModule { }
