import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

import { ActivatedRoute, Router } from '@angular/router';
import { Store } from 'src/app/core/model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, FormControl, FormArray } from '@angular/forms';
import { VALIDATOR_TEXT_LENGTH, CATEGORIES, ONLINE_CHANNELS, A11Y_OPTIONS } from 'src/app/core/model';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as _ from 'lodash';
import { A11yOption } from '../../core/model';


@Component({
  selector: 'app-edit-store',
  templateUrl: './edit-store.component.html',
  styleUrls: ['./edit-store.component.scss']
})
export class EditStoreComponent implements OnInit {
  store: Store;
  isPublished: boolean;
  isInit = false;


  // tags
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes = [ENTER, COMMA];


  categories = CATEGORIES;
  onlineChannels = ONLINE_CHANNELS;
  accessibleOptions = A11Y_OPTIONS;
  form: UntypedFormGroup;
  validTextLength = VALIDATOR_TEXT_LENGTH;



  //
  // 가게 주소로 위경도 얻기
  kakao;  // window['daum']
  geocoder; // for get latlng value with addr
  
  
  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) { }


  ngOnInit() {
    this.initKakaoGeoCoder();
    this.getStoreById();    
  }

  getStoreById() {
    let docId;
    this.activatedRoute.params.forEach((urlParams) => {
      docId = urlParams.id;
    });
    console.log('docId', docId);
    this.appService.getStoresAdmin()
      .subscribe(stores => {
        const matchedStore = _.find(stores, { 'id': docId });
        if(matchedStore) {
          console.log('matchedStore', matchedStore);
          this.store = matchedStore;
          this.initEachForms();
        } else {
          console.error('matchedStore is not founded', docId);
        }

        
        // console.log(this.store);
        // this.isPublished = this.store.isPublished;
        
      });
  }

  initEachForms() {
    
    const categoryIndex = _.findIndex(this.categories, category => {
      return category.ko === this.store.category.ko;
    });

    const _keySentence = (this.store.keySentence)? this.store.keySentence : null;

    this.form = this.fb.group({
      isPublished: [this.store.isPublished, [
        Validators.required
      ]],
      name: [this.store.name, [
        Validators.required,
        Validators.minLength(this.validTextLength.name.min),
        Validators.maxLength(this.validTextLength.name.max),
      ]],
      category: this.fb.control(this.categories[categoryIndex],  Validators.required),

      keySentence: new FormControl(_keySentence, [
        Validators.required,
        Validators.minLength(this.validTextLength.keySentence.min),
        Validators.maxLength(this.validTextLength.keySentence.max),
      ]),
      addr: [this.store.addr, [Validators.required]],
      lat: [this.store.lat],
      lng: [this.store.lng],

      summary: [this.store.summary, [
        Validators.required,
        Validators.minLength(this.validTextLength.summary.min),
        Validators.maxLength(this.validTextLength.summary.max),
      ]],

      notes: this.fb.array([ ]),
      openingInfos: this.fb.array([]),
      phone: [this.store.phone],
      onlineChannels: this.fb.array([]),
      menuList: this.fb.array([]),
      imgContentLink: [this.store.imgContentLink],

      a11yOptions: this.fb.array([]),


      tags: [ this.store.tags || [] ],
    });


    // formArray 초기화
    this.initFormWithFormArray('notes');
    this.initFormWithFormArray('openingInfos');
    this.initFormWithFormArray('onlineChannels');
    this.initFormWithFormArray('menuList');
    this.initFormWithFormArray('a11yOptions');
    this.isInit = true;
  }

  // FormArray 항목을 store 데이터로 구성하여 초기화
  initFormWithFormArray(controlName: string) {
    const control = this.form.controls[controlName] as UntypedFormArray;
    // DB에서 가져온 각 Store 데이터 내 작성된 항목(목록)을 기준으로
    // 편집가능하도록 FormGroup으로 만든 후 FormArray에 push.
    let targetArr = this.store[controlName];


    // only for legacy to current update (2023)
    // 모든 기존 가게 업데이트 마친 후  삭제 
    if (controlName === 'a11yOptions' && !targetArr) {
      targetArr = this.store.accessibleOptions;
    }

    console.log(this.store);

    switch (controlName) {
      case 'notes':
        if (targetArr) {
          _.forEach(targetArr, target => {
            const note = target.note;
            const eachItem = this.fb.group({
              note: this.fb.control(note, Validators.maxLength(this.validTextLength.notes.max)),
              trackingId: _.uniqueId()
            });
            control.push(eachItem);
          });
        }
        break;
      case 'openingInfos':
        if (targetArr) {
          _.forEach(targetArr, target => {
            const eachItem = this.fb.group({
              day: this.fb.control(target.day, Validators.required),
              open: this.fb.control(target.open, Validators.required),
              close: this.fb.control(target.close, Validators.required),
              trackingId: _.uniqueId()
            });
            control.push(eachItem);
          });
        }
        break;
      case 'onlineChannels':
          if (targetArr) {
            _.forEach(targetArr, target => {
              const onlineChannelIndex = _.findIndex(this.onlineChannels, onlineChannel => {
                return onlineChannel.ko === target.type.ko;
              });
              const eachItem = this.fb.group({
                type: this.fb.control(this.onlineChannels[onlineChannelIndex],  Validators.required),
                url: this.fb.control(target.url, Validators.required),
                trackingId: _.uniqueId()
              });
              control.push(eachItem);
            });
          }
          break;
      case 'menuList':
          if (targetArr) {
            _.forEach(targetArr, target => {
              const eachItem = this.fb.group({
                name: this.fb.control(target.name, Validators.required),
                price: this.fb.control(target.price, [
                  Validators.required,
                  Validators.pattern("^[0-9]*$")
                ]),
                trackingId: _.uniqueId()
              });
              control.push(eachItem);
            });
          }
          break;
      case 'a11yOptions':
        console.log('a11yOptions!', targetArr);
        _.forEach(targetArr, (a11yOption: A11yOption) => {
          control.push(this.fb.group({
            name: a11yOption.name,
            name_en: a11yOption.name_en,
            state: a11yOption.state,
            moreInfos: this.setMoreInfos(a11yOption)
          }));
        });
        break;
      default:
        console.error('initFormWithFormArray is not working.');
        break;
    } 
  }


    /** 2019ver A11yOption
     * desc: string (없애기)
     * name: string
     * name_en: string
     * state: boolean
     */
    
  // a11yOpitons -- start -- 
  setA11yOptions() {
    let control = this.form.controls.a11yOptions as FormArray;

    _.forEach(this.store.accessibleOptions, (a11yOption: A11yOption) => {
      control.push(this.fb.group({
        name: a11yOption.name,
        name_en: a11yOption.name_en,
        state: a11yOption.state,
        moreInfos: this.setMoreInfos(a11yOption)
      }));
    });
    console.log('control after ForEach', control);
  }



  setMoreInfos(a11yOption: A11yOption) {
    let arr = new FormArray([]);
    _.forEach(a11yOption.moreInfos, (moreInfo) => {
      arr.push(this.fb.group({ text: moreInfo.text }));
    });
    return arr;
  }


  addNewMoreInfoText(control) {
    control.push(
      this.fb.group({
        text: ['']
      }))
  }

  deleteMoreInfoText(control, index) {
    control.removeAt(index)
  }

  // a11yOpitons -- end -- 





  initSubItems(controlName: string) {
    console.log('initSubItems', controlName);

    switch (controlName) {
      case 'notes':
        return this.fb.group({
          note: this.fb.control(null, Validators.maxLength(this.validTextLength.notes.max)),
          trackingId: _.uniqueId()
        });
      case 'openingInfos':
          return this.fb.group({
            day: this.fb.control(null, Validators.required),
            open: this.fb.control(null, Validators.required),
            close: this.fb.control(null, Validators.required),
            trackingId: _.uniqueId()
          });
      case 'onlineChannels':
        return this.fb.group({
          type: this.fb.control(null, Validators.required),
          url: this.fb.control(null, Validators.required),
          trackingId: _.uniqueId()
        });
      case 'menuList':
        return this.fb.group({
          name: this.fb.control(null, Validators.required),
          price: this.fb.control(null, Validators.required),
          trackingId: _.uniqueId()
        });
      default:
        console.error('initSubItems controlName is undefined');
        break;
    }
  }



  addGroup(controlName: string) {
    const control = this.form.controls[controlName] as UntypedFormArray;
    control.push(this.initSubItems(controlName));
  }


  removeGroup(i: number, controlName: string) {
    const control = this.form.controls[controlName] as UntypedFormArray;
    control.removeAt(i);
  }
  trackByOption(index: number, item: A11yOption) {
    return item;
  }
  trackByFn(index: number, item: any) {
    return item.trackingId;
  }

  editField() {
    console.log('editField!');
    const newStore: Store = {
      isPublished: this.form.get('isPublished').value,

      keySentence: this.form.get('keySentence').value,
      name: this.form.get('name').value,
      category: this.form.get('category').value,

      addr: this.form.get('addr').value,
      lat: this.form.get('lat').value,
      lng: this.form.get('lng').value,
      summary: this.form.get('summary').value,
      notes: this.form.get('notes').value,

      openingInfos: this.form.get('openingInfos').value,
      onlineChannels: this.form.get('onlineChannels').value,
      phone: this.form.get('phone').value,

      // mainImg: this.form.get('mainImg').value,
      // imgList: this.form.get('imgList').value,
      menuList: this.form.get('menuList').value,
      imgContentLink: this.form.get('imgContentLink').value,

      // accessibleOptions: this.store.accessibleOptions,
      a11yOptions: this.form.get('a11yOptions').value,

      tags: this.form.get('tags').value,
      createdAt: this.store.createdAt,
      updatedAt: new Date()
    };


    this.appService
      .setDocWithMerge('stores', this.store.id, newStore)
      .then( () => {
        const routeDelay = 1400; // 완료 후 페이지 전환 딜레이
        // this.appService.setDocId('stores', newStore.createdAt);
        this.matSnackBar.open('수정 완료', '', { duration: routeDelay });
        setTimeout(() => { this.gotoPage('admin'); }, routeDelay);
      })
      .catch(err => console.error(err));
  }

  gotoPage(path: string) {
    this.router.navigate([path]);
  }



  // init kakao geocoder (for get lnglat by address)
  initKakaoGeoCoder() {
    if (window['daum']) {
      this.kakao = window['daum'];
      this.geocoder = new this.kakao.maps.services.Geocoder();
      console.log('init kakao gecoder');
    } else {
      console.error('kakaoMap SDK is not founded.');
    }
  }

  // 가게 주소로 위경도 값을 얻음 (with kakao geocoder)
  getLngLatByAddr(storeAddr: string) {

    const currentTypedAddr = this.form.value['addr'];
    
    // addr: this.form.get('addr').value,
    // console.log(currentTypedAddr);

    // 위경도값이 입력되지 않은 경우에만 실행
    // geocoder 상태가 참인 경우
    if (this.geocoder) {
      // Kakao Geocoder에 위경도 요청
      this.geocoder.addressSearch(storeAddr, (result, status) => {
        
        if (status === this.kakao.maps.services.Status.OK) {

          console.log(`${result[0].y}, ${result[0].x}`);

          // 정상 동작 시 store 위경도 업데이트 
          this.form.get('lat').patchValue(result[0].y);
          this.form.get('lng').patchValue(result[0].x);

          // store.lat = result[0].y;
          // store.lng = result[0].x;
        } else {
          // 검색 안될 경우, 오류 메시지 반환
          // ==> 잘못된 주소라면 modal 구성 필요
          console.error(`get geocode by addr is not working, ${storeAddr}`);
        }

      });
    } else {
      console.error('geocoder is not founded');
    }

  }



  toggleState(controlName: string, index?: number, event?: any) {
    switch (controlName) {
      case 'isPublished':
        this.form
        this.isPublished = !this.isPublished;
        break;
      // case 'accessibleOptions':
      //   this.store.accessibleOptions[index].state = !this.store.accessibleOptions[index].state;
      //   break;
      default:
        console.error('EditStore toggleState is not working');
        break;
    }
  }



  checkFormValue() {
    console.log('현재 폼 값', this.form.value);
    console.log('현재 accessibleOptions 값', this.store.accessibleOptions);
  }





  addTag(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;
    const currentArr = this.form.get('tags').value;

    if (value) {
      const convertedValue = _.toLower(value);
      const updatedArr = _.concat(currentArr, convertedValue.replace(/ /g, ''));
      this.form.get('tags').patchValue(updatedArr);
    }
    // Reset the Input value
    if (input) { input.value = ''; }
  }
  removeTag(tag: any) {
   //  console.log('removeTag', tag);
    const targetArr = this.form.get('tags');
    const beforeArr = targetArr.value;
    const afterArr = _.pull(beforeArr, tag);
    targetArr.patchValue(afterArr);
 }

}
