export interface Roles {
  visitor?: boolean;
  admin?: boolean;
}

export interface User {
  uid: string;
  name?: string;
  email: string;
  roles: Roles;
  lastLogin?: any;
}

export const ONLINE_CHANNELS = [
  { ko: "인스타그램", en: "instagram" },
  { ko: "페이스북", en: "facebook" },
  { ko: "유튜브", en: "youtube" },
  { ko: "트위터", en: "twitter" },
  { ko: "웹사이트", en: "website" },
  { ko: "블로그", en: "blog" },
  { ko: "카페", en: "cafe" },
  { ko: "브런치", en: "brunch" },
];

export const CATEGORIES = [
  { ko: "카페", en: "cafe" },
  { ko: "빵집", en: "bakery" },
  { ko: "음식점", en: "restaurant" },
  { ko: "주점", en: "bar" },

  { ko: "슈퍼마켓", en: "supermarket" },
  { ko: "식료품점", en: "grocery_store" },

  { ko: "병원", en: "hospital" },
  { ko: "한의원", en: "oriental_clinic" },
  { ko: "약국", en: "pharmacy" },

  { ko: "갤러리", en: "gallery" },
  { ko: "극장", en: "theater" },

  { ko: "문화공간", en: "culture_space" },
  { ko: "숙소", en: "bnb" },

  { ko: "서점", en: "bookstore" },
  { ko: "공방", en: "workshop" },

  { ko: "운동센터", en: "fitness" },
  { ko: "웨딩홀", en: "weddinghall" },
];

// ACCESSIBLE_OPTIONS : admin(add, edit), model.ts에서만 사용

export const A11Y_OPTIONS: A11yOption[] = [
  {
    state: false,
    name: "턱 없는 입구",
    name_en: "ent_no_doorsill",
    desc: "입구에 턱이 없어 휠체어 진입이 가능함",
    moreInfos: [
      { text: 'sample text' }
    ]
  },
  {
    state: false,
    name: "고정식 경사로",
    name_en: "ent_ramp_fixed",
    desc: "경사로가 있어 휠체어가 가게 내부에 진입할 수 있음",
  },
  {
    state: false,
    name: "이동식 경사로",
    name_en: "ent_ramp_portable",
    desc: "턱이 있지만 이동식 경사로가 비치되어 있어 휠체어 진입이 가능함",
  },
  {
    state: false,
    name: "휠체어 리프트",
    name_en: "ent_lift",
    desc: "턱이 있지만 휠체어 리프트가 있어 휠체어가 가게 내부에 진입할 수 있음",
  },
  {
    state: false,
    name: "엘리베이터",
    name_en: "ent_elevator",
    desc: "엘리베이터가 있어 휠체어 진입이 가능함",
  },
  {
    state: false,
    name: "주차 가능",
    name_en: "parking",
    desc: "주차 할 수 있는 공간이 있음",
  },

  {
    state: false,
    name: "휠체어 진입 가능 화장실",
    name_en: "restroom_wheelchair",
    desc: "휠체어로 접근할 수 있는 화장실이 있음",
  },
  {
    state: false,
    name: "성중립 화장실",
    name_en: "restroom_inclusive",
    desc: "남성/여성으로 구분하지 않고 사용할 수 있는 성중립 화장실이 있음",
  },
  {
    state: false,
    name: "성중립 휠체어 진입 가능 화장실",
    name_en: "restroom_inclusive_wheelchair",
    desc: "휠체어로 접근할 수 있는 성중립 화장실이 있음",
  },

  {
    state: false,
    name: "LGBTQ+ 환영",
    name_en: "lgbtaiq",
    desc: "성별, 성 정체성, 성적 지향을 이유로 차별하지 않을 것을 약속함",
  },
  {
    state: false,
    name: "모든 연령 환영",
    name_en: "all_ages",
    desc: "나이가 적거나 많다는 이유로 차별하지 않을 것을 약속함",
  },
  {
    state: false,
    name: "발달장애인 환영",
    name_en: "developmental_disability",
    desc: "발달장애인이라는 이유로 차별하지 않을 것을 약속함",
  },
  {
    state: false,
    name: `피부색/출신지역 관련없이 환영`,
    name_en: "all_skin",
    desc: "피부색, 인종, 언어가 다르다는 이유로 차별하지 않을 것을 약속함",
  },

  {
    state: false,
    name: "음성 안내",
    name_en: "support_voice",
    desc: "요청시 직원이 메뉴를 음성으로 안내하고 주문 및 활동을 지원함",
  },
  {
    state: false,
    name: "문자 안내",
    name_en: "support_text",
    desc: "요청시 문자로 대화를 주고 받거나 주문을 할 수 있게 안내하고 활동을 지원함",
  },
  {
    state: false,
    name: "수어 안내",
    name_en: "signlanguage",
    desc: "가게에 수어로 소통이 가능한 사람이 있음",
  },

  {
    state: false,
    name: "동물 동반 가능",
    name_en: "pet",
    desc: "동물과 함께 가게에 들어갈 수 있음",
  },
  {
    state: false,
    name: "유아차 동반 가능",
    name_en: "babycarriage",
    desc: "가게 내부에 유아차와 함께 들어갈 수 있음",
  },

  {
    state: false,
    name: "점자 메뉴판",
    name_en: "menu_braille",
    desc: "점자 메뉴판이 있음",
  },
  {
    state: false,
    name: "AAC 메뉴판",
    name_en: "menu_aac",
    desc: "발달장애나 언어장애가 있는 사람도 주문할 수 있게 그림이나 사진으로 된 AAC(보완대체의사소통) 메뉴판이 있음",
  },
  {
    state: false,
    name: "채식 메뉴",
    name_en: "vegetarian_menu",
    desc: "고기, 달걀, 유제품, 꿀 등을 사용하지 않은 비건(vegan) 메뉴가 있음",
  },
];

export const VALIDATOR_TEXT_LENGTH = {
  name: { min: 1, max: 12 },
  keySentence: { min: 2, max: 14 },
  summary: { min: 4, max: 64 },
  notes: { min: 2, max: 64 },
};

export interface OpeningInfo {
  day: string;
  open: string;
  close: string;
}
export interface OnlineChannel {
  type: string;
  url: string;
}

export interface Menu {
  name: string;
  price: string;
}

export interface Lang {
  name?: string; // 기본 표시
  ko: string;
  en?: string;
}

export interface ImageObj {
  url: string;
  alt: string;
}

// 위와 구분된 이유를 찾아봅시다.
// AccessibleInfo : a11y 목록의 항목을 클릭했을 때 표시되는 snackbar 데이터
// - storeBottomsheet
// - listBbottomsheet

export const AccessibleInfo = {
  // ent: entrance
  ent_no_doorsill: {
    name: "턱 없는 입구",
    desc: "입구에 턱이 없어 휠체어 진입이 가능함",
  },
  ent_ramp_fixed: {
    name: "고정식 경사로",
    desc: "경사로가 있어 휠체어가 가게 내부에 진입할 수 있음",
  },
  ent_ramp_portable: {
    name: "이동식 경사로",
    desc: "턱이 있지만 이동식 경사로가 비치되어 있어 휠체어가 가게 내부에 진입할 수 있음",
  },
  ent_lift: {
    name: "휠체어 리프트",
    desc: "턱이 있지만 휠체어 리프트가 있어 휠체어가 가게 내부에 진입할 수 있음",
  },
  ent_elevator: {
    name: "엘리베이터",
    desc: "엘리베이터가 있어 휠체어가 가게 내부에 진입할 수 있음",
  },

  // restroom
  restroom_wheelchair: {
    name: "휠체어 화장실",
    desc: "휠체어로 접근할 수 있는 화장실이 있음",
  },
  restroom_inclusive: {
    name: "성중립 화장실",
    desc: "남성/여성으로 구분하지 않고 사용할 수 있는 성중립 화장실이 있음",
  },
  restroom_inclusive_wheelchair: {
    name: "성중립 휠체어 화장실",
    desc: "휠체어로 접근할 수 있는 성중립 화장실이 있음",
  },

  //
  lgbtaiq: {
    name: "LGBTQ+ 환영",
    desc: "성별, 성 정체성, 성적 지향을 이유로 차별하지 않을 것을 약속함",
  }, // 성별, 성정체성, 성적 지향으로 인한 차별
  all_ages: {
    name: "모든 연령 환영",
    desc: "나이가 적거나 많다는 이유로 차별하지 않을 것을 약속함",
  }, // 모든 연령
  developmental_disability: {
    name: "발달장애인 환영",
    desc: "발달장애인이라는 이유로 차별하지 않을 것을 약속함",
  }, // 발달장애인
  support_voice: {
    name: "음성 안내",
    desc: "요청시 직원이 메뉴를 음성으로 안내하고 주문 및 활동을 지원함",
  }, // 음성 안내
  support_text: {
    name: "문자 안내",
    desc: "요청시 문자로 대화를 주고 받거나 주문을 할 수 있게 안내하고 활동을 지원함",
  }, // 텍스트 주문(?)

  pet: { name: "동물 동반가능", desc: "동물과 함께 가게에 들어갈 수 있음" }, // 동물 동반 가능
  babycarriage: {
    name: "유아차 동반가능",
    desc: "가게 내부에 유아차와 함께 들어갈 수 있음",
  }, // 유아차

  menu_braille: { name: "점자 메뉴판", desc: "점자 메뉴판이 있음" }, // 점자 메뉴판
  menu_aac: {
    name: "AAC 메뉴판",
    desc: "발달장애나 언어장애가 있는 사람도 주문할 수 있게 그림이나 사진으로 된 AAC (보완대체의사소통) 메뉴판이 있음",
  }, // AAC 메뉴판
  vegetarian_menu: {
    name: "채식 메뉴",
    desc: "달걀, 고기, 유제품, 꿀 등을 전혀 사용하지 않은 비건(vegan) 메뉴가 있음",
  }, // 채식 메뉴가 있음
  signlanguage: {
    name: "수어 가능",
    desc: "가게에 수어로 소통이 가능한 사람이 있음",
  }, // 수어 가능
  parking: { name: "주차 가능", desc: "주차 할 수 있는 공간이 있음" },
};

// for admin
// -
// export interface AccessibleOptions {
// 		// 01. 공간 접근성
//         ent_no_doorsill: boolean;					// 턱없는 입구
//         ent_ramp_fixed: boolean;					// 고정식 경사로
//         ent_ramp_portable: boolean;					// 이동식 경사로
//         ent_lift: boolean;							// 휠체어 리프트
//         ent_elevator: boolean;						// 엘리베이터
// 		parking: boolean;							// 주차 가능

//         restroom_wheelchair: boolean;				// 휠체어 진입 가능 화장실
//         restroom_inclusive: boolean;				// 성중립 화장실
//         restroom_inclusive_wheelchair: boolean;		// 성중립 휠체어 진입 가능 화장실

//         // 03. 마음 접근성
//         lgbtaiq: boolean;                           // LGBTQ+ 환영
//         all_ages: boolean;                          // 모든 연령 환영
// 		all_skin?: boolean;							// 피부색/출신지역 관련없이 환영
//         developmental_disability: boolean; 			// 발달장애인 환영
// 		babycarriage: boolean;                     	// 유아차 동반 가능
// 		pet: boolean;                              	// 동물 동반 가능

// 		// 02. 정보 접근성
//         support_voice: boolean;                 	// 음성 안내
//         support_text: boolean;                  	// 문자 안내

//         menu_braille: boolean;                     	// 점자 메뉴판
//         menu_aac: boolean;                          // AAC 메뉴판
//         vegetarian_menu: boolean;                  	// 채식 메뉴 있음
//         signlanguage: boolean;                  	// 수어 가능
// }

/**  Store Interface 정리
 * id?: document id
 * isPublished?: 발행상태 (발행중/미발행)
 * name: 가게이름 (DB에서는 string)
 * keySentence: 핵심 문장
 * summary: 간단 요약
 *
 * category?: 업종 정보
 * addr: 가게 주소
 * lat?: 위도
 * lng?: 경도
 * openingInfos : 운영시간 (요일, 여는시간 - 닫는시간)
 * onlineChannels : 온라인 채널 (instagram, blog 등)
 * phone : 가게 전화번호
 * menuList? : 가게 대표 메뉴
 * notes?: string[] // 특이사항
 * accessibleOptions: AccessibleOption[] // 가게 접근성 정보
 *
 * imgContentLink?: 가게 소개 콘텐츠 링크 (클릭 시 차없가 인스타그래 게시물로 이동)
 *
 * 미사용 변수
 * tags?: 가게 태그
 * mainImg?: ImageObj // 가게 메인 이미지
 * imgList?: ImageObj[] // 가게 이미지 리스트
 *
 * 개선필요 변수
 * isExpanded?: ListBottomsheet 에서 확장/축소 상태 표시위한 변수. 삭제 필요
 * name: Lang
 * isExpanded?: boolean (listBottomsheet에서 열림/닫힘 관리 변수)
 */

export interface Store {
  id?: any;
  isPublished?: boolean;

  name: string;
  
  // name_en?: string;
  keySentence?: string;  // 핵심 문장

  summary: string;
  category?: Lang;


  // category_en?: string;

  addr: string;
  lat?: any;
  lng?: any;

  openingInfos?: OpeningInfo[];
  onlineChannels?: OnlineChannel[];
  phone?: string;
  menuList?: Menu[];
  notes?: string[];

  // 접근성 정보 (legacy)
  accessibleOptions?: any;
  a11yOptions: Array<A11yOption>;


  tags?: any[];
  imgContentLink?: string;

  mainImg?: ImageObj;
  imgList?: ImageObj[];

  isExpanded?: boolean;

  createdAt?: any;
  updatedAt?: any;
}

export interface A11yOption {
  state: boolean;
  name: string;
  name_en: string;
  desc?: string;

  moreInfos?: Array<MoreInfo>; // 가게 별 옵션 추가 도움말
}



export interface MoreInfo {
  text: string;
}





