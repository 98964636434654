import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store, AccessibleInfo, OnlineChannel } from 'src/app/core/model';
import { Router } from '@angular/router';
import { toCommaString } from 'src/app/utils/toCommaString';
import { A11yOption } from '../../core/model';

@Component({
  selector: 'app-store-bottomsheet',
  templateUrl: './store-bottomsheet.component.html',
  styleUrls: ['./store-bottomsheet.component.scss']
})
export class StoreBottomsheetComponent implements OnInit {
  isExpanded = false;
  accessibleInfo = AccessibleInfo;
  store: Store;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<StoreBottomsheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: Store,
    public matSnackBar: MatSnackBar
  ) { 
    this.store = data;
  }

  ngOnInit() {
    this.isExpanded = false;
  }
  
  toggleExpandState() {
    this.isExpanded = !this.isExpanded;
  }
  closeSheet() {
    this.bottomSheetRef.dismiss();
  }


}
