<section class="admin">
	<mat-card class="admin-card is-table">
		<mat-card-header>
			<mat-card-title>
				<ng-container *ngIf="stores">
					<span [matBadge]="stores.length" matBadgeSize="medium" matBadgeOverlap="false">참여 가게 목록</span>
				</ng-container>
			</mat-card-title>
			<mat-card-subtitle>
				현재 등록된 차별없는가게 목록입니다.

				
				
			</mat-card-subtitle>
		</mat-card-header>


		<div class="admin-table-container">
			<mat-card-content>
				
				<div class="table-toolbar">
					<mat-form-field appearance="standard"
						class="table-filter">
						<mat-label>필터</mat-label>
						<input matInput (keyup)="applyFilter($event)" placeholder="가게 이름" #input>
					</mat-form-field>
					<button class="btn-add"
						color="primary" 
					 	type="button" 
						(click)="gotoPage('add-store')">
						새로운 가게 추가
					</button>
				</div>
				
				<!-- table -->
				<!-- <div class="table-container"></div> -->
					<mat-table class="admin-table" #table 
						[dataSource]="dataSource" matSort matSortDisableClear
						[trackBy]="trackByCreatedAt"
						style="overflow: auto">
						<!-- multiTemplateDataRows -->

						<!-- 발행상태 isPublished -->
						<!-- [sticky]="isSticky('isPublished')" -->
						<ng-container matColumnDef="isPublished" sticky>
							<mat-header-cell *matHeaderCellDef mat-sort-header>발행상태</mat-header-cell>
							<mat-cell *matCellDef="let store">
								<span [class.is-active]="store.isPublished">
									{{ (store.isPublished)? '발행중' : '대기' }}
								</span>
								
								<!-- <mat-icon>{{
									store.isPublished
									? "radio_button_checked"
									: "radio_button_unchecked"
									}}</mat-icon> -->
							</mat-cell>
						</ng-container>

						<!-- [sticky]="isSticky('name')" -->
						<!-- 이름 -->
						<ng-container matColumnDef="name" sticky>
							<mat-header-cell *matHeaderCellDef mat-sort-header>가게 이름</mat-header-cell>
							<mat-cell *matCellDef="let store">{{ store.name }}</mat-cell>
						</ng-container>
						<!-- 카테고리 cateogory -->
						<ng-container matColumnDef="category">
							<mat-header-cell *matHeaderCellDef mat-sort-header>분류</mat-header-cell>
							<mat-cell *matCellDef="let store">{{ store.category.ko }}</mat-cell>
						</ng-container>

						<!-- 한줄 소개 keySentence -->
						<ng-container matColumnDef="keySentence">
							<mat-header-cell *matHeaderCellDef mat-sort-header>한줄 소개</mat-header-cell>
							<mat-cell *matCellDef="let store">{{ store.keySentence }}</mat-cell>
						</ng-container>
						<!-- 요약 summary -->
						<ng-container matColumnDef="summary">
							<mat-header-cell *matHeaderCellDef mat-sort-header>간단 소개</mat-header-cell>
							<mat-cell *matCellDef="let store">{{ store.summary }}</mat-cell>
						</ng-container>

						<!-- 전화번호 phone -->
						<ng-container matColumnDef="phone">
							<mat-header-cell *matHeaderCellDef mat-sort-header>번호</mat-header-cell>
							<mat-cell *matCellDef="let store">{{ store.phone }}</mat-cell>
						</ng-container>
						<!-- 주소 addr -->
						<ng-container matColumnDef="addr">
							<mat-header-cell *matHeaderCellDef mat-sort-header>주소</mat-header-cell>
							<mat-cell *matCellDef="let store">{{ store.addr }}</mat-cell>
						</ng-container>

						<!-- 등록일 cateogory -->
						<ng-container matColumnDef="createdAt">
							<mat-header-cell *matHeaderCellDef mat-sort-header>생성일</mat-header-cell>
							<mat-cell *matCellDef="let store">{{
								store.createdAt.toDate() | date : "y년 M월 d일 H:m"
								}}</mat-cell>
						</ng-container>

						<!-- 수정 edit -->
						<ng-container matColumnDef="edit" stickyEnd>
							<mat-header-cell *matHeaderCellDef>수정</mat-header-cell>
							<mat-cell *matCellDef="let store">
								<button mat-icon-button (click)="gotoPage('edit-store', store)" aria-label="콘텐츠 수정">
									<mat-icon>edit</mat-icon>
								</button>
							</mat-cell>
						</ng-container>

						<!-- 삭제 delete -->
						<ng-container matColumnDef="delete" stickyEnd>
							<mat-header-cell *matHeaderCellDef>삭제</mat-header-cell>
							<mat-cell *matCellDef="let store">
								<button mat-icon-button (click)="openDeleteDialog(store)" aria-label="콘텐츠 삭제">
									<mat-icon>delete</mat-icon>
								</button>
							</mat-cell>
						</ng-container>

						<!-- 헤더영역 -->
						<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

					</mat-table>
					<!-- <mat-paginator [pageSize]="30" [pageSizeOptions]="[20, 40, 80, 100]" showFirstLastButtons></mat-paginator> -->
				

			</mat-card-content>
		</div>


	</mat-card>




</section>