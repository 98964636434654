<section class="stores-container">
	<ng-container *ngIf="stores">
		<!-- <button id="btn-store-list" class="btn-view_list" 
			#btnStoreList
			mat-stroked-button 
			(click)="toggleViewType()"
			type="button">
			<figure class="icon-container" aria-hidden="true">
				<img src="/assets/images/ui/icons/view_list@2x.png" alt="" class="icon">
			</figure>
			<span>목록으로 보기</span>
		</button> -->

		<app-map [stores]="stores"></app-map>
	</ng-container>

</section>

