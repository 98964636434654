import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddStoreComponent } from './add-store/add-store.component';
import { EditStoreComponent } from './edit-store/edit-store.component';
import { DeleteStoreComponent } from './delete-store/delete-store.component';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';

export const ADMIN_COMPONENTS = [
  AdminComponent,
  AddStoreComponent,
  EditStoreComponent,
  DeleteStoreComponent,
];
export const ADMIN_ENTRY_COMPONENTS = [
  DeleteStoreComponent
];

export const ADMIN_MATERIAL_MODULES = [
  // table
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,

  // form
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatSlideToggleModule,

  // tools
  MatIconModule,
  MatSnackBarModule,
  MatDialogModule,

   // have to check
  //  MatExpansionModule,
   MatChipsModule,
  //  MatTabsModule,
   MatCardModule,
   MatDividerModule,
  //  MatStepperModule,
   MatTooltipModule,
   MatBadgeModule,
   MatSelectModule,
   MatOptionModule
];


@NgModule({
    declarations: [
        ADMIN_COMPONENTS,
        ADMIN_ENTRY_COMPONENTS,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AdminRoutingModule,
        ADMIN_MATERIAL_MODULES
    ]
})
export class AdminModule { }
