<!-- skip navigation -->
<div id="a11y-controls">
	<a class="nav-link" href="stores#btn-view_list">참여가게 목록 바로가기</a>
	<!-- <a class="nav-link" [href]="skipLinkPath">참여가게 목록 바로가기</a> -->
</div>

<header role="banner">
	<div class="nav-container">

		<!-- 로고 영역 -->
		<h1>
			<a class="logo-header" routerLink="/stores" routerLinkActive="active">

				<!-- a) img with alt (w3c st, voiceover에서 어떻게 들리는 지 확인)-->
				<img aria-hidden="true" class="nav-logo" src="assets/images/ui/logo@2x.png" alt="" />
				<span class="cdk-visually-hidden">차별없는가게 home</span>
				<!-- a) "link 차별없는가게 home 배너" -->


				<!-- <img class="nav-logo" src="assets/images/ui/logo@2x.png" alt="차별없는가게 home" /> -->
				<!-- b) a with backgroundimg (nulli st) -->
				<!-- <span class="cdk-visually-hidden">차별없는가게</span> -->
			</a>
		</h1>

		<!-- 메인 메뉴 -->
		<nav class="menu-main">
			<!-- aria-label과 중복 표시 아닌지 확인 -->
			<h2 class="is-visually-hidden">메인 메뉴</h2>

			<!-- 메뉴 영역 -->
			<a *ngIf="route !== '/about' && route !== '/admin'" 
				routerLink="/about" class="nav-item"
				routerLinkActive="is-active">
				소개
			</a>

			<!-- 관리자 페이지 only -->
			<a *ngIf="route === '/admin'" (click)="signOut()" class="nav-item">로그아웃</a>


			<!-- 소개 페이지 > 언어 select -->
			<mat-form-field *ngIf="route === '/about'" appearance="none" floatLabel="never">
				<mat-icon class="icon-language" matTextPrefix>language</mat-icon>

				<mat-select disableOptionCentering="false" [value]="langs[0]"
					(selectionChange)="onSelectionChange($event.value)">

					<mat-option *ngFor="let lang of langs" [value]="lang">
						{{lang.name}}
					</mat-option>
				</mat-select>
			</mat-form-field>

		</nav>
	</div>
</header>


<main id="content">


	<!-- 목록 보기 버튼 -->
	<button 
		*ngIf="stores && route !== '/about' && route !== '/admin'"
		id="btn-view_list" 
		class="btn-view_list"
		mat-stroked-button type="button"
		 (click)="onViewStoreList()">
		<figure class="icon-container" aria-hidden="true">
			<img src="/assets/images/ui/icons/view_list@2x.png" alt="" class="icon">
		</figure>
		<span>목록 보기</span>
	</button>

	<router-outlet></router-outlet>
</main>


<!-- 널리 접근성 기능
<nav id="a11yMenu" class="a11y_menu" aria-label="접근성메뉴" style="position: absolute; top: 568px;">
        <h2 class="blind">접근성메뉴</h2>
        <ul>
                <li class="a11y_invert"><button id="setInvert" aria-pressed="false"><span class="icon"></span><span>색상반전</span></button></li>
                <li class="a11y_zoomin"><button id="setZoomin"><span class="icon"></span><span>확대</span></button></li>
                <li class="a11y_zoomout"><button id="setZoomout"><span class="icon"></span><span>축소</span></button></li>
                <li class="a11y_magnify"><button id="setMagnify" aria-pressed="false"><span class="icon"></span><span>돋보기</span></button></li>
        </ul>
        <dl aria-live="polite">
                <dt class="blind">확대수치</dt>
                <dd id="zoomNum">100</dd>
        </dl>
</nav> 
-->