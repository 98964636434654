<!-- <section id="map"></section> -->


<!-- stuckyistudio token map [style]="'mapbox://styles/stuckyistudio/clc5xb40k003z14msf2xup01w'" -->
<!-- [style]="'mapbox://styles/stuckyistudio/clc5xb40k003z14msf2xup01w'" -->

<!-- map list
    // google map style 
		// 차별없는가게 제주 v1.0
    mapbox://styles/tadkim/cldsgrl28002j01qszg5bewu0

    // monochrome (tadkim)
    mapbox://styles/tadkim/clc4qfs75003m14msy4n13h3w

-->
<!-- maxZoom: 16 -->

<section class="map-container">
	<div class="contents-wrapper">
		<div class="btn-region-container">
			<mat-button-toggle-group #group="matButtonToggleGroup">
				<mat-button-toggle [class.is-active]="currentRegion === 'seoul'" value="서울" type="button"
					(click)="onRegion('seoul')">
					<figure *ngIf="currentRegion === 'seoul'" class="icon-container" aria-hidden="true">
						<img class="icon" src="/assets/images/ui/icons/check@2x.png" alt="">
					</figure>
					<span class="region-text">서울</span>
				</mat-button-toggle>

				<mat-button-toggle value="제주" [class.is-active]="currentRegion === 'jeju'" (click)="onRegion('jeju')">
					<figure *ngIf="currentRegion === 'jeju'" class="icon-container" aria-hidden="true">
						<img class="icon" src="/assets/images/ui/icons/check@2x.png" alt="">
					</figure>
					<span class="region-text"> 제주</span>
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
	</div>


	<div class="map-area" *ngIf="mapConfig">
		<mgl-map id="mapbox-container" [style]="'mapbox://styles/tadkim/cldsgrl28002j01qszg5bewu0'"
			[center]="mapConfig.center" [zoom]="[mapConfig.zoomLev]" [minZoom]="6" [centerWithPanTo]="true"
			(zoomEnd)="onZoom($event)" (mapLoad)="onLoadMap($event)">



			<!-- 거리 정보 -->
			<!-- <mgl-control mglScale unit="metric" position="bottom-left"></mgl-control> -->

			<div class="contents-wrapper">
				<mgl-control mglNavigation [showCompass]="false" position="bottom-right"></mgl-control>
			</div>
			<!-- 줌인아웃 -->
			

			<!-- marker -->
			<ng-container *ngFor="let store of stores">
				<mgl-marker [lngLat]="[store.lng, store.lat]">
					<figure class="marker-img-container" (click)="onMarker(store)">
						<img class="marker-img" [src]="'/assets/images/map/icons/category/outline/' + store.category.en + '@2x.png'"
							alt="">
					</figure>
				</mgl-marker>


			</ng-container>


		</mgl-map>
	</div>
</section>