import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-svg',
  templateUrl: './icon-svg.component.html',
  styleUrls: ['./icon-svg.component.scss']
})
export class IconSvgComponent implements OnInit {
  @Input() svgUrl: string;
  @Input() svgStyle: any;

  constructor() { }

  ngOnInit() {
  }

}
