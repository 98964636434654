import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// local auth feature
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';

// modules
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';

export const CORE_COMPONENTS = [
  LoginComponent
];
export const CORE_MODULES = [
  AngularFireAuthModule,
  AngularFirestoreModule,
];

export const CORE_MATERIAL_MODULES = [
  MatSnackBarModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule
];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    CORE_MODULES,
    CORE_MATERIAL_MODULES
  ],
  declarations: [
    CORE_COMPONENTS
  ],
  providers: [AuthService, AuthGuard, AdminGuard]
})
export class CoreModule { }
