import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store, OnlineChannel, AccessibleInfo } from 'src/app/core/model';
import { AppService } from 'src/app/app.service';
import { toCommaString } from 'src/app/utils/toCommaString';
import { A11yOption } from '../../core/model';

@Component({
  selector: 'app-list-bottomsheet',
  templateUrl: './list-bottomsheet.component.html',
  styleUrls: ['./list-bottomsheet.component.scss']
})
export class ListBottomsheetComponent implements OnInit {
  // stores = STORES;

  // for custom mat expansioon-toggle directive
  panelOpenState = false;





  // legacy -- 
  stores;
  accessibleInfo = AccessibleInfo;
  isExpanded = false;


  
  constructor(
    private appService: AppService,
    private bottomSheetRef: MatBottomSheetRef<ListBottomsheetComponent>,
    public matSnackBar: MatSnackBar,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) private data: Store[]
  ) {
    this.stores = this.data;
    this.stores.forEach( (store) => {
      store.isExpanded = false;
    });
   }


  ngOnInit() {
    this.isExpanded = false;
  }




  onExpansionHeader(store: Store) {
    console.log('onExpansionHeader', store.name);
    store.isExpanded = !store.isExpanded;
  }






  // legacy 


  toggleExpansion(index: number) {
    
    this.stores[index].isExpanded = !this.stores[index].isExpanded;
  }


  openLink(event: MouseEvent) {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
  closeSheet() {
    this.bottomSheetRef.dismiss();
  }
  toComma(price: any) {
    return toCommaString(price);
  }


  onAccessibleOption(option: A11yOption) {
    const selectedOption = this.accessibleInfo[option.name_en];
    if (selectedOption) {
      const msg = `${selectedOption.name}: ${selectedOption.desc}`;
      this.matSnackBar.open(msg, '', { duration: 3500 });
    } else  {
      console.error('onAccessibleOption, option is undefined');
    }
  }


  openWindow(url: string) {
    window.open(url);
  }


}
