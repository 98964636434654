
<section class="dialog-container is-notice">
        <!-- <div class="dialog-btn-container">
             
        </div> -->
        <button class="btn-close" 
                mat-icon-button 
                aria-label="닫기 버튼"
                (click)="closeDialog('close')">
                <mat-icon>close</mat-icon>
        </button>

        <div class="dialog-body">
                <figure class="dialog-img-container">
                        <img class="dialog-img"
                                src="/assets/images/stores/megaphone@2x.png" 
                                alt="" >
                </figure>
                <p>
                        차별없는가게 프로젝트에 대한 문의는
                        아래 다이애나랩 메일로 보내주세요.
                        dianalab00@gmail.com
                </p>
                <p>  
                        개별 가게는 ‘가게'로 이용해주세요.
                        영업 중인 곳에 가서 프로젝트에 대한
                        소개나 투어를 요청하시면 곤란합니다.
                        
                        
                        
                </p>
                <p>
                        감사합니다.
                </p>
          
        </div>
        <div class="dialog-bottom">
            
        </div>

</section>