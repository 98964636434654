<section class="admin">
	<form class="form-group" *ngIf="isInit" [formGroup]="form" (ngSubmit)="editField()">
		<!-- 상단 메뉴바 -->
		<section class="nav-admin mat-elevation-z1">
			<div class="nav-items">
				<div class="nav-item-group is-left">
					<button type="button" mat-flat-button (click)="gotoPage('admin')">&lt; 뒤로가기</button>
				</div>
				<div class="nav-item-group is-right">
					<button mat-flat-button type="button" (click)="gotoPage('admin')">취소</button>
					<button color="primary" mat-flat-button type="submit" [disabled]="!form.valid">저장</button>
				</div>
			</div>
		</section>



		<!-- 정보 입력 영역 시작 -->
		<section class="admin-header">
			<p class="admin-header-title">가게 정보 수정</p>
			
			<mat-slide-toggle 
				class="slide-toggle" 
				color="primary" 
				[disabled]="!form.valid"
				formControlName="isPublished">
			</mat-slide-toggle>
			<mat-label class="slide-toggle-label">{{ (form.get('isPublished').value)? "발행중" : "발행 준비중" }}</mat-label>
		</section>


		<mat-card class="admin-card">
			<mat-card-header>
				<mat-card-title>기본 정보</mat-card-title>
				<mat-card-subtitle>가게의 기본 정보를 입력해주세요</mat-card-subtitle>
			</mat-card-header>
			<mat-card-content>


        <div class="inner-container">
          <div class="card-container">
            <div class="card-row">
              <mat-form-field appearance="outline">
                <mat-label>가게 이름</mat-label>
                <input matInput #name formControlName="name" type="text" placeholder="예: 스투키 스튜디오" required />
                <mat-hint align="end">{{ name.value.length }} /
                  {{ validTextLength.name.max }}</mat-hint>
              </mat-form-field>
            </div>


            <div class="card-row">
              <mat-form-field appearance="outline">
                <mat-label>간단 요약</mat-label>
                <input matInput #keySentence
                  formControlName="keySentence" 
                  [maxlength]="validTextLength.keySentence.max"
                  type="text" placeholder="간단히 '카페'라고만 적어도 됩니다." required />
                  <mat-hint align="end">
                    {{ keySentence.value.length }} /
                    {{ validTextLength.keySentence.max }}
                  </mat-hint>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>간단 소개</mat-label>
                <textarea matInput #summary
                  class="card-textarea"
                  [maxlength]="validTextLength.summary.max"
                  formControlName="summary" type="text" 
                  placeholder="정보 위주의 짧은 문장" required>
                </textarea>
                <mat-hint align="end">
                  {{ summary.value.length }} /
                  {{ validTextLength.summary.max }}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="preview-container">
            <span class="preview-info">가게 정보 표시 미리보기</span>
            <div class="preview">
              <div class="preview-header">
                <div class="title-container">
                  <p class="store-category">{{ form.get('keySentence').value}}</p>
                  <h2 class="store-name">{{ form.get('name').value }}</h2>
                </div>
                <button class="btn-close" 
                  mat-icon-button type="button" 
                  aria-label="닫기">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <div class="store-summary">
                <p> {{ form.get('summary').value }}</p>
              </div>
            </div>

          </div>
        </div>




		
			</mat-card-content>
		</mat-card>


		
    <mat-card class="admin-card">
      <mat-card-header>
        <mat-card-title>공간 정보</mat-card-title>
        
      </mat-card-header>

      <mat-card-content>

				<div class="card-row">
					<mat-form-field appearance="outline" class="is-col-4">
						<mat-label>가게 주소</mat-label>
						<input matInput #addr formControlName="addr" type="text" placeholder="지번주소 + 층" required />
						<mat-hint align="start">예 서울시 서대문구 연희동 708, 1층</mat-hint>
					</mat-form-field>
					<button type="button" mat-flat-button color="primary" 
						[disabled]="!form.get('addr').valid"
						(click)="getLngLatByAddr(addr.value)">
					주소로 위치 얻기
				</button>
				</div>

				

				<div class="card-row">
					<mat-form-field appearance="outline" class="is-col-4">
						<mat-label>업종 선택</mat-label>
						<mat-select formControlName="category">
							
							<mat-option *ngFor="let category of categories" [value]="category">{{category.ko}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="card-row">
					<mat-form-field appearance="outline" class="is-col-2">
						<mat-label>위도</mat-label>
						<input matInput #lat formControlName="lat" type="text">
						<mat-hint align="start">예시) 37.xxxxx</mat-hint>
					</mat-form-field>
					<mat-form-field appearance="outline" class="is-col-2">
						<mat-label>경도</mat-label>
						<input matInput #lng formControlName="lng" type="text">
						<mat-hint align="start">예시) 127.xxxxx</mat-hint>
					</mat-form-field>
				</div>

				<div class="card-row">
					<mat-form-field appearance="outline" class="is-col-4">
						<mat-label>전화번호</mat-label>
						<input matInput #phone formControlName="phone" placeholder="070-1234-4568" type="text" />
					</mat-form-field>
				</div>


				<div class="card-row">
					<mat-form-field appearance="outline" class="is-col-4">
						<mat-label>가게 이미지 게시물 링크</mat-label>
						<input matInput #imgContentLink formControlName="imgContentLink" type="text"
							placeholder="https://www.instagram.com/p/B57tP0_J-Qb/" />
						<mat-hint align="start">SNS 게시물 링크를 복사하여 넣어주세요</mat-hint>
					</mat-form-field>
				</div>

				<p class="step-title">운영시간</p>
				<div class="card-row" formArrayName="openingInfos"
					*ngFor="let openingInfo of form.controls.openingInfos?.value; let i = index; trackBy:trackByFn">

					<div class="form-item" [formGroupName]="i">
						<mat-form-field class="is-col-2" appearance="outline">
							<mat-label>요일(예: 월-토)</mat-label>
							<input matInput placeholder="예) 월-토 or 평일" formControlName="day">
						</mat-form-field>
						<mat-form-field class="is-col-2" appearance="outline">
							<mat-label>여는시간</mat-label>
							<input matInput placeholder="예) 09:00" formControlName="open">
						</mat-form-field>
						<mat-form-field class="is-col-2" appearance="outline">
							<mat-label>닫는시간</mat-label>
							<input matInput placeholder="예) 21:00" formControlName="close">
						</mat-form-field>
						<button mat-icon-button aria-label="현재 운영시간 1개 지우기" (click)="removeGroup(i, 'openingInfos')">
							<mat-icon>close</mat-icon>
						</button>
					</div>
				</div>
				<button class="btn-add" type="button" mat-stroked-button (click)="addGroup('openingInfos')">+ 운영시간 추가</button>



				<p class="step-title">온라인 채널</p>
				<div class="card-row" formArrayName="onlineChannels"
					*ngFor="let onlineChannel of form.controls.onlineChannels?.value; let i = index; trackBy:trackByFn">
					<div class="form-item" [formGroupName]="i">
						<mat-form-field appearance="outline">
							<mat-label>플랫폼 종류</mat-label>
							<mat-select formControlName="type">
								
								<mat-option *ngFor="let onlineChannel of onlineChannels"
									[value]="onlineChannel">{{onlineChannel.ko}}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field appearance="outline" class="is-col-4">
							<mat-label>URL</mat-label>
							<input matInput placeholder="https://struckyi.studio" formControlName="url">
						</mat-form-field>
						<button mat-icon-button aria-label="현재 온라인 채널 1개 지우기" (click)="removeGroup(i, 'onlineChannels')">
							<mat-icon>close</mat-icon>
						</button>
					</div>
				</div>
				<button class="btn-add" type="button" mat-stroked-button (click)="addGroup('onlineChannels')">+ 채널 정보
					추가</button>



				<p class="step-title">메뉴 목록</p>
				<div class="card-row" formArrayName="menuList"
					*ngFor="let menu of form.controls.menuList?.value; let i = index; trackBy:trackByFn">

					<div class="form-item" [formGroupName]="i">
						<mat-form-field appearance="outline">
							<mat-label>메뉴 이름</mat-label>
							<input matInput placeholder="name" formControlName="name">
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>가격</mat-label>
							<input matInput placeholder="price" formControlName="price">
							<span matSuffix>원</span>
							<mat-hint align="start">숫자만 입력해주세요</mat-hint>
						</mat-form-field>
						<button mat-icon-button aria-label="현재 메뉴 1개 지우기" (click)="removeGroup(i, 'menuList')">
							<mat-icon>close</mat-icon>
						</button>
					</div>
				</div>
				<button class="btn-add" type="button" mat-stroked-button (click)="addGroup('menuList')">+ 메뉴 추가</button>

				<p class="step-title">특이사항</p>
				<div class="card-row" formArrayName="notes"
					*ngFor="let note of form.controls.notes?.value; let i = index; trackBy:trackByFn">
					<div class="form-item" [formGroupName]="i">
						<mat-form-field appearance="outline" class="is-col-6">
							<textarea #notesInput matInput 
								placeholder="가게 방문 전 영업시간을 확인하는 것이 좋음" 
								formControlName="note"></textarea>
						</mat-form-field>
						<button mat-icon-button aria-label="현재 메모 1개 지우기" (click)="removeGroup(i, 'notes')">
							<mat-icon>close</mat-icon>
						</button>
					</div>
				</div>
				<button class="btn-add" type="button" mat-stroked-button (click)="addGroup('notes')">+ 정보 추가</button>




			</mat-card-content>
		</mat-card>



    <mat-card class="admin-card">
      <mat-card-header>
        <mat-card-title>접근성 정보</mat-card-title>
        <mat-card-subtitle>가게의 접근성 정보를 입력해주세요.</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <!-- <button mat-raised-button type="button" (click)="checkFormValue()">
          폼값 확인
        </button> -->
        <div class="card-row" formArrayName="a11yOptions">
          <ng-container *ngFor="
              let a11yOption of form.get('a11yOptions')['controls'];
              let i = index">
            <div class="form-item is-column is-outline" [formGroupName]="i">
              <div class="form-item-row">
                <mat-slide-toggle class="slide-toggle is-aceessibleicon" color="primary"
                  formControlName="state"></mat-slide-toggle>
                <h3>{{ a11yOption.get("name").value }}</h3>
              </div>
              <div class="form-item-list" formArrayName="moreInfos">
                <ul>
                  <li class="list-item" *ngFor="
                      let moreInfo of a11yOption.get('moreInfos')['controls'];
                      let j = index
                    ">
                    <ng-container [formGroupName]="j">
                      <mat-form-field appearance="outline">
                        <textarea matInput placeholder="화장실입구에 턱이 있어 휠체어 진입 불가능" formControlName="text"></textarea>
                      </mat-form-field>

                      <button class="btn-delete" mat-icon-button aria-label="텍스트 1개 삭제" (click)="
                          deleteMoreInfoText(
                            a11yOption['controls']['moreInfos'],
                            j
                          )">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </ng-container>
                  </li>

                  <button class="btn-add" mat-stroked-button 
										(click)="addNewMoreInfoText(a11yOption['controls']['moreInfos'])" type="button">
                    텍스트 추가
                  </button>
                </ul>
              </div>
            </div>
          </ng-container>
        </div>

        <p class="step-title">태그</p>
        <mat-form-field>
          <mat-label>태그입력 후 Enter</mat-label>
          <mat-chip-list #tags>
            <ng-container *ngIf="form.get('tags').value.length">
              <mat-chip *ngFor="let tag of form.get('tags').value" [selectable]="selectable" [removable]="removable"
                (click)="addTag(tag)" (remove)="removeTag(tag)">
                {{ tag }}
                <mat-icon *ngIf="removable" matChipRemove (click)="removeTag(tag)">cancel</mat-icon>
              </mat-chip>
            </ng-container>
            <input placeholder="태그를 입력후 Enter" [matChipInputFor]="tags"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addTag($event)" required />
          </mat-chip-list>
        </mat-form-field>
      </mat-card-content>
    </mat-card>


	</form>
</section>