<section class="hero" aria-hidden="true">
	<img *ngIf="lang?.value === 'ko'" src="assets/images/about/hero/ko@2x.png" class="hero-img" alt="">
	<img *ngIf="lang?.value === 'en'" src="assets/images/about/hero/en@2x.png" class="hero-img" alt="">
</section>

<section class="about">
	<ng-container *ngIf="lang?.value === 'ko'">
		<p>
			<span aria-hidden="true">&lt;</span>차별없는가게<span aria-hidden="true">&gt;</span>는 사회적 소수자가 지역사회에서 차별받지 않고, 동등한 하나의
			개인으로 받아들여질 것을 동네 식당이나 카페 등 일상에 가까운 가게로부터 약속받아 지도에 표시하는 프로젝트입니다. 장애 등 본인의 정체성을 이유로 쫓겨나거나 불안해하지 않을 수 있는 공간을 늘리는 것이
			목표입니다.
		</p>
	</ng-container>

	<ng-container *ngIf="lang?.value === 'en'">
		<p>
			"Project We Welcome All" is a mapping project to map the neighborhood diners or cafes that promise to accept
			social minorities as equal individuals and not be discriminated in communities.
		</p>
		<p>
			The project aims to increase spaces that will not reject people or make them feel uneasy because of their
			identities such as disabilities.
		</p>
	</ng-container>

	<img class="about-graphic-middle" src="assets/images/about/graphic-middle@2x.png" alt="" />

	<ng-container *ngIf="lang?.value === 'ko'">
		<p>
			그렇지만 <span aria-hidden="true">&lt;</span>차별없는가게<span aria-hidden="true">&gt;</span>가 완벽하게 차별이 없는 공간을 검열하거나 인증하려는
			것은 아닙니다. 낯선 상대방을 서로 이해하고 존중하려는 최소한의 노력, 그 가능성과 과정에 대한 불가능한 약속을 한번 해보려는 것입니다.
		</p>

		<p>
			우리는 서로에게 아직 많이 모자란 존재이겠지만,'차별을 하지 않겠다'는 약속 아래 서로의 세계에 조금 더 다가갈 수 있을 것입니다.
		</p>
	</ng-container>
	<ng-container *ngIf="lang?.value === 'en'">
		<p>
			Nevertheless, Project We Welcome All is not designed to censor or approve places for perfectly discrimination-free
			space.
		</p>


		<p>
			Rather, the project attempts to make an impossible promise to understand and respect the unfamiliar other with
			whatever effort for the possibilities and the process.
		</p>
		<p>
			We may still be imperfect beings to one another, but the promise not to discriminate may bring us closer to one
			another’s world.
		</p>
	</ng-container>


	<!-- video a11y test -->

	<section class="video-container" [class.is-lang-en]="lang?.value === 'en'">
		<h3 class="is-visually-hidden">
			{{ (lang?.value === 'ko')? '차별없는가게 소개 영상' : 'Project Introduction Video' }}
		</h3>
		<div class="aspect-ratio-container">
			<video controls="">
				<!-- poster="/assets/videos/wewelcomeall_about.png"  -->
				<!-- videos -->
				<source src="/assets/videos/wewelcomeall_about.mp4" type="video/mp4">
				<source src="/assets/videos/wewelcomeall_about.webm" type="video/webm">
				<!-- text track file -->
				<track default kind="captions" label="한국어 자막" srclang="ko" src="/assets/videos/wewelcomeall_about_caption_ko.vtt">
				
				
				<track kind="captions" label="English captions" srclang="en" src="/assets/videos/wewelcomeall_about_caption_en.vtt">

				<!-- fallback for browsers that don't support the video element -->
				<div>
					<a href="/assets/videos/wewelcomeall_about.mp4" href="#" target="_blank">
						<img src="/assets/videos/wewelcomeall_about.png" alt="download video" width="640" height="360">
					</a>
				</div>
			</video>
		</div>

	</section>


	<!-- <div class="video-container"
                 style="padding:56.25% 0 0 0; position:relative;">
                <iframe title="차별없는가게 프로젝트 소개 영상"
                        src="https://player.vimeo.com/video/383456774?autoplay=1&title=0&byline=0&portrait=0" 
                        style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                        frameborder="0"  
                        allowfullscreen>
                </iframe>
			</div> -->


</section>

<section class="about">
	<h2>
		<ng-container *ngIf="lang?.value === 'ko'">
			<img class="about-promissory" src="assets/images/about/code-of-conduct/ko@2x.png" alt="">
			<span class="is-visually-hidden">약속문</span>
		</ng-container>
		<ng-container *ngIf="lang?.value === 'en'">
			<img class="about-promissory" src="assets/images/about/code-of-conduct/en@2x.png" alt="">
			<span class="is-visually-hidden">code of conduct</span>
		</ng-container>
	</h2>

	<ul class="about-sentence">
		<ng-container *ngIf="lang?.value === 'ko'">
			<li *ngFor="let sentence of sentences.ko">
				{{sentence}}
			</li>
		</ng-container>
		<ng-container *ngIf="lang?.value === 'en'">
			<li *ngFor="let sentence of sentences.en">
				{{sentence}}
			</li>
		</ng-container>
	</ul>


</section>





<footer role="contentinfo">

	<h2 class="is-visually-hidden">콘텐츠 정보</h2>
	<div class="footer-container">
		<div class="contents-wrapper">
			<h3 class="is-visually-hidden">연락하기</h3>
			<div class="footer-header">

				<!-- 로고 영역 -->
				<h3 class="footer-logo">
					<a routerLink="/stores" routerLinkActive="active">
						<img class="logo" src="/assets/images/ui/logo_dark@2x.png" alt="차별없는가게 home" />
					</a>
				</h3>
				<p class="footer-msg">
					<ng-container *ngIf="lang?.value === 'ko'">
						함께하고 싶으시거나 궁금한 점이 있다면 연락해주세요.
					</ng-container>
					<ng-container *ngIf="lang?.value === 'en'">
						If you wish to take part in the Project We Welcome All,
						or have any question, feel free to contact us.
					</ng-container>
				</p>
			</div>


			<div class="footer-body">
				<!-- <p>dianalab00@gmail.com</p> -->

				<ul class="sns-infos">
					<li class="sns-info">
						<figure class="icon-container is-sns" aria-hidden="true">
							<img class="icon" src="/assets/images/ui/icons/sns/standard/email@2x.png" alt="">
						</figure>
						<a class="sns-link is-link" 
							href="mailto:dianalab00@gmail.com">
							이메일 dianalab00@gmail.com</a>
						<!-- <span class="is-visually-hidden">인스타그램</span> -->
					</li>
					<li class="sns-info">
						<figure class="icon-container is-sns" aria-hidden="true">
							<img class="icon" src="/assets/images/ui/icons/sns/standard/instagram@2x.png" alt="">
						</figure>
						<!-- <span class="is-visually-hidden">인스타그램</span> -->
						<a class="sns-link is-link" href="https://www.instagram.com/dianalab000" target="_blank">
							인스타그램 @dianalab000
						</a>
					</li>

				</ul>

				<div class="inner-section">
					<h3>함께 보기</h3>
					<ul class="ref-items">
						<li class="ref-item">
							<a class="is-link" href="https://firebasestorage.googleapis.com/v0/b/inclusive-store-kr.appspot.com/o/2022%E1%84%82%E1%85%A7%E1%86%AB%20%E1%84%8E%E1%85%A1%E1%84%87%E1%85%A7%E1%86%AF%E1%84%8B%E1%85%A5%E1%86%B9%E1%84%82%E1%85%B3%E1%86%AB%E1%84%80%E1%85%A1%E1%84%80%E1%85%A6_list.pdf?alt=media&token=7cbccf86-a503-437b-add0-1cf91fcd90ec" 
								target="_blank">
								차별없는가게 참여가게 목록(PDF,288KB)
							</a>
						</li>
						<li class="ref-item">
							<a class="is-link" href="https://wwa.school" target="_blank">
								공간학교WWA
							</a>
						</li>
					</ul>
				</div>


			</div> <!-- .footer-body -->
		</div>


	</div> <!--. footer-container -->

	<div class="credit-container">
		<div class="contents-wrapper">
			<h3>함께 만든 사람들</h3>

			<ul class="credit-list">
				<li class="credit">
					<span class="credit-name">기획·운영</span>
					<ul class="credit-value-list">
						<li class="credit-value"><a target="_blank" href="https://www.instagram.com/dianalab000">다이애나랩</a></li>
						<li class="credit-value"><a target="_blank" href="https://www.instagram.com/infoshop_cafe_byulkkol/">인포숍카페별꼴</a></li>
					</ul>
					
				</li>
				<li class="credit">
					<span class="credit-name">웹사이트 제작</span>
					<span class="credit-value">
						<a target="_blank" href="https://stuckyi.studio">스투키 스튜디오</a>
					</span>
				</li>
				<!-- <li class="credit">
					<span class="credit-name">가게 정보 수집</span>
					<ul class="credit-value-list">
						<li class="credit-value"><span>쭈</span></li>
						<li class="credit-value"><span>유정</span></li>
						<li class="credit-value"><span>돌고래</span></li>
						<li class="credit-value"><span>승관</span></li>
						<li class="credit-value"><span>모든</span></li>
						<li class="credit-value"><span>이야기</span></li>
					</ul>
				</li> -->

			</ul>
		</div>

	</div> <!-- .credit-container -->
	<!-- <div class="copyright-container">
		<div class="contents-wrapper">
			<p class="copyright">© 2023. 다이애나랩 all rights reserved.</p>
		</div>
	</div> -->



</footer>