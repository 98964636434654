import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-stores-dialog',
  templateUrl: './stores-dialog.component.html',
  styleUrls: ['./stores-dialog.component.scss']
})
export class StoresDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<StoresDialogComponent>
  ) { }

  ngOnInit() {
  }

  closeDialog(state: string) {
    this.dialogRef.close('cancel');
  }
}
